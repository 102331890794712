.report {
	img {
		border: 1px solid $border-grey;
	}
	
	.button, .menu {
		margin-top: 1.5rem;
		float: left;
	}
}

.report-info {
	h3 {
		@extend h2;
		
		text-transform: lowercase;
		
		&::first-letter {
			text-transform: capitalize;
		}
	}
}