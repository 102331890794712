@mixin accelerated() {
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
}

@mixin col($width) {
	width: (($width / 12) * 100%);
	min-height: 1px;
}

p {
	padding: .4em 0;
}

article ul, article ol {
	padding-left: 2em;
	
	li {
		margin-bottom: 2px;
	}
}

a {
	color: inherit;
}

img {
	max-width: 100%;
}

hr {
	border: 0;
	height: 1px;
	background: $border-grey;
	margin: 1.5rem 0 .5rem;
}

blockquote {
	background: $light-grey;
	margin: .75rem 0 1.5rem;
	padding: .15rem .75rem;
	font-style: italic;
	border: 1px solid $border-grey;
	
	footer {
		margin-bottom: .3rem;
	}
	
	cite {
		font-style: normal;
		
		&::before {
			content: "- ";
		}
	}
}

.splash {
	font-size: 2.7rem;
	padding-bottom: 0;
}

.right {
	float: right !important;
}

.left {
	float: left !important;
}

.center {
	text-align: center;
}

.notes {
	color: $text-grey;
}

.clear {
	overflow: auto;
}

.secondary {
	color: $text-grey;
	text-decoration: none;
	
	h1 & {
		color: $text-light-grey;
		margin-left: .2em;
	}
}

.animated {
	@include accelerated();
}

.text-uppercase  {text-transform: uppercase}
.text-lowercase  {text-transform: lowercase}
.text-capitalize {text-transform: capitalize}

.hidden, .is-hidden {display: none !important}
.visually-hidden {visibility: hidden !important}
.visible {display: block !important}

.row {
	padding: 1.5rem .75rem;
	
	&.full {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
	}
}

.row + .row {
	padding-top: 0;
}

.link {
	color: $blue;
	text-decoration: underline;
}

.overflow-visible {overflow: visible !important}
.overflow-hidden {overflow: hidden !important}

.scroll-columns {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 10;
	margin: 0;
	padding: 0 .75rem;
	overflow: hidden;
	
	> .col-1, > .col-2, > .col-3, > .col-4, > .col-5, > .col-6, > .col-7, > .col-8, > .col-9, > .col-10, > .col-11, > .col-12 {
		overflow: auto;
		height: 100%;
		padding: 1.5rem .75rem;
		-webkit-overflow-scrolling: touch;
	}
}

.col-1  {@include col(1) }
.col-2  {@include col(2) }
.col-3  {@include col(3) }
.col-4  {@include col(4) }
.col-5  {@include col(5) }
.col-6  {@include col(6) }
.col-7  {@include col(7) }
.col-8  {@include col(8) }
.col-9  {@include col(9) }
.col-10 {@include col(10) }
.col-11 {@include col(11) }
.col-12 {@include col(12) }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
	float: left;
	padding: 0 .75rem;
	
	&.animated {
		@include accelerated();
		
		transition: width .3s;
	}
	
	&.right {
		float: right;
	}
	
	&.first {
		padding-left: 0;
	}
	
	&.last {
		padding-right: 0;
	}
}

.col-fixed {
	position: fixed;
	width: 275px;
	padding-right: 1.5rem;
	
	@media screen and (max-width: 1100px) {
		width: 25%;
	}
}

.col-shadow {
	float: right;
	box-shadow: -5px 1rem 5px -5px $border-grey;
}

.row {
	.col-bordered-right {padding-right: 1.5rem}
	.col-bordered-left {padding-left: 1.5rem;}
	.col-bordered {
		padding-right: 1.5rem;
		padding-left: .75rem;
	}
}