.navigation {
	list-style: none;
	
	a {
		display: block;
		padding: .4rem .6rem .3rem;
		text-decoration: none;
		border-left: .5rem solid $green;
		
		&:hover, &.is-active {
			background: $light-grey;
		}
	}
		
	li:nth-child(4n+1) a {border-color: $green}
	li:nth-child(4n+2) a {border-color: $blue}
	li:nth-child(4n+3) a {border-color: $yellow}
	li:nth-child(4n+4) a {border-color: $red}
}