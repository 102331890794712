////////////////////////////////////////////////////////////////////////////////
// General Buttons
////////////////////////////////////////////////////////////////////////////////

$button-gradient-top: #FED93C;
$button-gradient-bottom: #F7A502;
$secondary-gradient-top: #F8F8F8;
$secondary-gradient-bottom: #DADADA;

button, .button, input[type=submit] {
	background: $yellow;
	border: 0;
	padding: .35rem 1.2rem;
	height: 46px;
	font-family: $font-league;
	font-size: 1.8rem;
	border-radius: .5rem;
	text-decoration: none;
	border: 1px solid darken($yellow, 10%);
	box-shadow: 0 1px 3px $border-grey;
	cursor: pointer;
	display: inline-block;
	color: inherit;
	background-image: -webkit-linear-gradient(to bottom, $button-gradient-top, $button-gradient-bottom);
	background-image: linear-gradient(to bottom, $button-gradient-top, $button-gradient-bottom);
	
	&:hover {
		background-image: -webkit-linear-gradient(to bottom, darken($button-gradient-top, 4%), darken($button-gradient-bottom, 4%));
		background-image: linear-gradient(to bottom, darken($button-gradient-top, 4%), darken($button-gradient-bottom, 4%));
	}
	
	&:active {
		background-image: -webkit-linear-gradient(to bottom, darken($button-gradient-bottom, 4%), darken($button-gradient-top, 4%));
		background-image: linear-gradient(to bottom, darken($button-gradient-bottom, 4%), darken($button-gradient-top, 4%));
	}
	
	&.secondary {
		border-color: #CCC;
		color: #333;
		background-image: -webkit-linear-gradient(to bottom, $secondary-gradient-top, $secondary-gradient-bottom);
		background-image: linear-gradient(to bottom, $secondary-gradient-top, $secondary-gradient-bottom);
	
		&:hover {
			background-image: -webkit-linear-gradient(to bottom, darken($secondary-gradient-top, 4%), darken($secondary-gradient-bottom, 4%));
			background-image: linear-gradient(to bottom, darken($secondary-gradient-top, 4%), darken($secondary-gradient-bottom, 4%));
		}
	
		&:active {
			background-image: -webkit-linear-gradient(to bottom, darken($secondary-gradient-bottom, 4%), darken($secondary-gradient-top, 4%));
			background-image: linear-gradient(to bottom, darken($secondary-gradient-bottom, 4%), darken($secondary-gradient-top, 4%));
		}
	}
	
	&.small {
		padding: 0.1rem 0.75rem;
		font-size: 1.6em;
		height: 35px;
		margin-top: -7px;
	}
}


////////////////////////////////////////////////////////////////////////////////
// Circle Icon Controls (Back Button)
////////////////////////////////////////////////////////////////////////////////

.control {
	display: block;
	text-decoration: none;
	padding: 1rem 0 1rem 2.75rem;
	position: relative;
	z-index: 10;
	color: $text-grey;
	
	&:before {
		font-family: Arial;
		height: 40px;
		width: 40px;
		font-weight: bold;
		color: #999;
		border: 4px solid #999;
		border-radius: 20px;
		overflow: hidden;
		display: block;
		position: absolute;
		font-size: 2rem;
		line-height: 34px;
		text-align: center;
		left: 0;
		top: .31rem;
	}
	
	&.back:before {
		content: "<";
		padding-right: .2rem;
	}
	
	&.add:before {
		content: "+";
	}
}


////////////////////////////////////////////////////////////////////////////////
// Menu (Student Picker)
////////////////////////////////////////////////////////////////////////////////

.menu {
	float: left;
	padding: 2px;
	border: 1px solid $border-grey;
	border-radius: .5rem;
	max-height: 47px;
	overflow: hidden;
	background: #FFF;
	transition: max-height linear .2s;
	cursor: pointer;
	position: relative;
	z-index: 10;
	
	&:after {
		content: "";
		width: 10px;
		height: 10px;
		display: block;
		position: absolute;
		right: 1rem;
		top: 1.04rem;
		border: {
			right: 7px solid transparent;
			left: 7px solid transparent;
			top: 10px solid #333;
		}
	}
	
	&.open {
		max-height: 400px;
		overflow: scroll;
		
		.active {
			background: $light-grey;
		}
	}
	
	a, .active {
		transition: all linear .15s;
		display: block;
		overflow: auto;
		padding-right: 3rem;
		border-radius: .4rem;
		
		&:hover {
			background: $light-grey;
		}
	}
	
	a {
		margin-top: 2px;
	}
	
	.label {
		padding: .65em .5em;
		float: left;
	}
	
	.utility {
		margin-top: 6px;
		padding-top: 2px;
		border-top: 1px solid $border-grey;
		
		a {
			margin-top: 0;
		}
		
		.label {
			padding: .2em .3em;
		}
	}
}


////////////////////////////////////////////////////////////////////////////////
// Tabs
////////////////////////////////////////////////////////////////////////////////

.tabs {
	position: absolute;
	top: 1.1rem;
	margin: 0 auto;
	width: calc(100% - 3rem);
	text-align: center;
	
	a {
		@extend h2;
		
		margin: 0 .25rem;
		padding: 0;
		line-height: 85%;
		text-transform: uppercase;
		display: inline-block;
		text-decoration: none;
		
		&.active {
			border-bottom: 4px solid #333;
		}
	}
}


////////////////////////////////////////////////////////////////////////////////
// Grade Icon Styles
////////////////////////////////////////////////////////////////////////////////

$border-lighten-amount: 30%;

.grade-icon {
	display: inline-block;
	height: 30px;
	width: 30px;
	border: 1px solid #FFF;
	outline: 3px solid #AAA;
	color: #333;
	font-family: $font-league;
	font-size: 30px;
	vertical-align: top;
	overflow: hidden;
	margin: 0;
	text-align: center;
	line-height: 30px;
	background: #AAA;
	position: relative;
	
	&.n-a {
		font-size: 1.3rem;
	} 
	
	&.a {
		background: $green;
		outline-color: $green;
	}
	
	&.b {
		background: $blue;
		outline-color: $blue;
	}
	
	&.c {
		background: $yellow;
		outline-color: $yellow;
	}
	
	&.d {
		background: $orange;
		outline-color: $orange;
	}
	
	&.f {
		background: $red;
		outline-color: $red;
	}
	
	sup {
		font-size: 18px;
		position: relative;
		line-height: 30px;
		vertical-align: top;
		top: -.45em;
		left: 1px;
		font-weight: bold;
		font-family: Impact, "Trebuchet MS", sans-serif;
	}
}

// Inline grade icon
h1 .grade-icon {
	top: .45rem;
	margin: 0 .25rem 0 .25rem;
}