html {
	box-sizing: border-box;
}

body {
	-webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
	box-sizing: inherit;
}

* {
	margin: 0;
	padding: 0;
}

.pipe-links {
	font-size: .9rem;
	
	a {
		padding: 0 .25em;
		text-decoration: none;
	
		&:hover {
			text-decoration: underline;
		}
	}
}

.container {
	max-width: 1100px;
	padding: 0 1.5rem;
	margin: 0 auto;
	
	> .row {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
	}
}


// Loading indicator for any element
@keyframes fadein {
	from {opacity: 0}
	to   {opacity: 1}
}

.is-loading {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 100;
	animation: fadein .2s;
	background: {
		color: $fade-out;
		image: url(../images/loading.gif);
		position: center;
		background-size: 50%;
		repeat: no-repeat;
	}
	
	.panel-wrapper & {
		margin-left: -1.5rem;
	}
	
	#sq-context-app-main > & {
		margin-top: .5rem;
		width: calc(100% - 100px);
	}
	
	.full-content > & {
		margin-top: .5rem;
	}
}

.circle-icon {
	height: 40px;
	width: 40px;
	float: left;
	border-radius: 50%;
	color: #FFF;
	font-weight: normal;
	text-align: center;
	padding-top: .65em;
	background: {
		color: #333;
		position: center;
		size: cover;
	}
	
	&.all {
		font-weight: bold;
	}
}

.students-count {
	display: block;
	padding: .75rem .1rem;
}