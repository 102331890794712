.attendance {
	width: 340px;
	
	.attending-column {
		width: 110px;
	}
	
	td.attending-column {
		padding-top: .6rem;
	}
	
	input[type=text] {
		width: 40px;
	}
	
	.form-row input[type=submit], .form-row .button {
		margin-top: 0;
	}
	
	.circle-icon {
		margin-top: -10px;
		margin-right: .5rem;
	}
}

.calendar-nav .dropdown-wrapper {
	margin-top: 1.2rem;
}