.calendar {
	td {
		width: (100% / 7);
		height: 11vw;
		position: relative;
		padding: .25rem;
		border: 1px solid $border-grey;
		
		.date {
			position: absolute;
			font-size: .9em;
			top: .2rem;
			left: .2rem;
			text-decoration: none;
		}
	}
	
	th {
		font-weight: normal;
		padding: 0 0 0 .3rem;
	}
	
	.inactive {
		background: $light-grey;
	}
	
	.dots {
		position: absolute;
		top: .3rem;
		right: .3rem;
	}
}

.dot {
	float: left;
	width: 12px;
	height: 12px;
	margin-right: 1px;
	border-radius: 6px;
}

.dot-label {
	float: left;
	padding-right: 1.5rem;
}

.dots-legend {
	padding: 1rem 0;
	
	.dot {
		margin-top: .25rem;
		margin-right: .2rem;
	}
}

.calendar-nav {
	position: relative;
	
	> h1 {
		position: absolute;
		padding-right: .7rem;
		white-space: nowrap;
	}
	
	.dropdown-wrapper {
		position: absolute;
		top: .25rem;
		right: 1.5rem;
	}
}

.prev-next {
	margin: 0 auto;
	display: block;
	width: 300px;
	text-align: center;
	position: relative;
	
	.prev, .next {
		width: 150px;
		display: block;
		padding: .75rem;
	}
	
	.prev {
		float: left;
		text-align: right;
	}
	
	.next {
		float: right;
		text-align: left;
	}
	
	.today {
		position: absolute;
		top: .75rem;
		right: -4rem;
	}
	
	.prev::before {
		content: "< ";
	}
	
	.next::after {
		content: " >";
	}
}

.event {
	font-size: .9em;
	border-radius: .15rem;
	padding: .1rem .2rem;
	text-decoration: none;
	display: block;
	margin: 1px 0;
	z-index: 2;
	
	.time {
		display: block;
	}
}

.schedule {
	&.col-6 {
		padding-top: 0;
	}
	
	.all-day {
		border-bottom: 1px solid $border-grey;
		display: flex;
		position: fixed;
		padding-bottom: 1px;
		background: #FFF;
		width: calc(50% - 5.4rem);
		z-index: 10;
		
		.hour-content {
			position: relative;
		}
		
		.event {
			position: static;
		}
	}
	
	.hour {
		display: flex;
		
		.hour-label {
			margin-top: -15px;
		}
		
		.hour-content {
			position: relative;
			height: 60px;
			border-bottom: 1px solid $border-grey;
		}
	}
	
	.event {
		padding: .25rem .35rem;
		margin: 1px;
		width: 100%;
		position: absolute;
		
		.name {
			font-weight: bold;
		}
	}
	
	.hour-label {
		@extend .label;

		text-align: right;
		padding: .3rem .5rem 0 0;
		width: 60px;
	}
	
	.hour-content {
		flex-grow: 1;
	}
}