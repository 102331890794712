.cvc-info {
	width: 400px;
	margin-right: -300px;
	
	&::before {
		left: 2rem;
	}
	
	&::after {
		left: 1.5rem;
	}
}

.payment-help {
	h3:first-child {
		margin: 3.9rem 0 .5rem;
	}
}