.sq-flash, .alert, .message {
	@include accelerated();
	
	max-height: 0;
	border: 1px solid #FFF;
	border-radius: .15rem;
	transition: max-height .1s, padding-top .1s, padding-bottom .1s, opacity .1s;
	margin: .5rem 0;
	overflow: auto;
	clear: both;
	opacity: 0;
	padding: .4rem .6rem;
	
	&:empty {
		margin: 0;
	}
	
	&.success, &.notice, &.info, &.warning, &.error, &.failure, &.danger {
		max-height: 60px;
		opacity: 1;
	}
	
	&.success, &.info {
		background: #C6DF7A;
		border-color: #98BF21;
	}
	
	&.notice {
		background: #8FAFC6;
		border-color: #6B899F;
	}
	
	&.warning, &.error {
		background: #FDDB82;
		border-color: #FABE1E;
	}
	
	&.failure, &.danger {
		background: #E38489;
		border-color: #C83038;
	}
}

.form-row {
	+ .message, + .sq-flash {
		margin-top: 1rem;
	}
}

.alert {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	position: fixed;
	top: .5rem;
	z-index: 50;
	width: 36vw;
	margin: 0;
	left: 32vw;
	border-top: 0;
	padding: .2rem 1.5rem .1rem .3rem;
}

.alert-close {
	position: absolute;
	right: .3rem;
	font-size: 1.4rem;
	font-weight: bold;
	cursor: pointer;
	margin-top: -2px;
	user-select: none;
}