.dropdown-wrapper {
	position: relative;
	
	table & {
		margin-top: 0;
	}
}

.dropdown-content {
	display: none;
	position: absolute;
	top: 2rem;
	right: -.5rem;
	padding: .5rem;
	box-shadow: 0 0 10px #999;
	z-index: 20;
	background: #FFF;
	border-radius: 9px;
	
	&::before {
		content: "";
		width: 20px;
		height: 20px;
		display: block;
		background: #FFF;
		box-shadow: 0 0 10px #999;
		transform: rotate(45deg);
		position: absolute;
		border-radius: 3px;
		right: 2rem;
		top: -.5rem;
	}
	
	&::after {
		content: "";
		width: 40px;
		height: 22px;
		background: #FFF;
		position: absolute;
		right: 1.5rem;
		top: 0;
	}
	
	* {
		position: relative;
		z-index: 2;
	}
}

.dropdown-content.is-open {
	display: block;
}

.dropdown-trigger {
	text-decoration: none;
	float: right;
	cursor: pointer;
	padding: 0 1rem;
	position: relative;
	
	&::after {
		content: "";
		display: block;
		width: 7px;
		height: 7px;
		border: 7px solid transparent;
		border-top: 10px solid #333;
		position: absolute;
		right: 0;
		top: calc(50% - 5px);
		
		table & {
			right: 1rem;
		}
	}
	
	&.is-active::after {
		border-bottom: 10px solid #333;
		border-top: 0 solid #333;
	}
	
	table & {
		padding: 0 2.1rem 0 0;
	}
}


////////////////////////////////////////////////////////////////////////////////
// Day Picker
////////////////////////////////////////////////////////////////////////////////

.day-picker {
	table-layout: fixed;
	width: 320px;
	margin-bottom: 1rem;
	
	td, th {
		width: 14.28%;
		text-align: center;
		
		input[type=checkbox] {
			margin: 0 0 0 .55rem;
		}
	}
	
	th {
		padding-bottom: .1rem;
	}
	
	td {
		border-top: 1px solid $border-grey;
		border-bottom: 1px solid $border-grey;
	}
}