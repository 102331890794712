table {
	border-collapse: collapse;
	width: 100%;
	
	th, td {
		padding: .5em;
	}
	
	th {
		text-align: left;
	}
	
	.thead-hidden th {
		height: 1px;
		overflow: hidden;
		padding: 0;
	}
}