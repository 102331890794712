#payment, #login {
	.site-nav, .header .button {
		display: none;
	}
}

.sign-up {
	float: right;
	margin-top: .8rem;
}

.main-section {
	@extend %chalkboard;
	
	.lead {
		color: #FFF;
		font-size: 1.3rem;
		text-align: center;
		margin: 0 auto;
		max-width: 900px;
		padding-top: 2.5rem;
	}
}

.site-content {
	@extend %below-header;
	
	overflow: auto;
	padding-bottom: 3rem;
	
	.video-wrapper {
		width: 100%;
		padding: 2.5rem 0;
		
		iframe {
			box-shadow: 0 0 5px #111;
		}
	}
}

.site-nav {
	font-size: 1.6em;
	font-family: $font-league;
	text-transform: uppercase;
	
	a {
		padding: .3em .2em 0;
		display: inline-block;
		text-decoration: none;
	}
}

.site-footer {
	padding: 1em 0;
	clear: both;
	
	small {
		font-size: 0.9rem; 
	}
	
	.pipe-links {
		float: right;
		margin-top: 2px;
	}
}

.social {
	img {
		position: relative;
		top: 7px;
		margin-right: .3rem;
	}
	
	a {
		margin-right: 1rem;
	}
	
	a:last-child img {
		top: 6px;
	}
}

.message-bar {
	width: 100%;
	position: fixed;
	top: 70px;
	z-index: 1;
	padding: .25rem 0;
	background-color: #8FAFC6;
	border-top: 1px solid #6B899F;
	border-bottom: 1px solid #6B899F;
	
	+ .site-content {
		padding-top: 6rem;
	}
}

.target {
	margin-top: -70px;
	padding-top: 70px;
}

.features-section {
	overflow: hidden;
	
	h3 {
		font-size: 2.7rem;
		line-height: 84%;
		text-transform: none;
	}
	
	img {
		position: relative;
		z-index: -1;
		border: 1px solid $border-grey;
		border-bottom: 0;
	}
	
	.row {
		overflow: auto;
		padding-bottom: 0;
		box-shadow: 0 -5px 10px -5px $border-grey;
		margin-top: -4px;
		z-index: 10;
		padding-top: 1.5rem;
		
		.col-6 {
			padding-top: 1.5rem;
		}
	}
	
	.row:nth-child(4n+2) h3 {color: $green}
	.row:nth-child(4n+3) h3 {color: $blue}
	.row:nth-child(4n+4) h3 {color: $yellow}
	.row:nth-child(4n+5) h3 {color: $red}
	
	h2 {
		font-size: 2.7rem;
		text-transform: uppercase;
		text-align: center;
	}
	
	.col-4 {
		padding-bottom: 1.5rem;
	}
}

.pricing {
	.col-12 {
		text-align: center;
	}
	
	h4 {
		font-size: 2.2rem;
		text-transform: uppercase;
		
		span {
			display: block;
			font-size: 1rem;
			text-transform: none;
			color: $text-default;
			margin-bottom: -7px;
			font-family: $font-proxima;
		}
	}
	
	.price {
		color: $blue;
		font-family: $font-league;
		font-size: 4rem;
	}
	
	.button {
		display: block;
		margin: 0 auto 2.5rem;
		width: 102px
	}
	
	.price-block {
		display: inline-block;
		padding: 1rem 0 1.5rem;
		width: 140px;
	}
}