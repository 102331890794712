.grade-breakdown {
	width: 340px;
	
	.grade-breakdown-input-column {
		flex-grow: 1;
		
		input {
			width: 80px;
		}
		
		label + input {
			width: auto;
		}
		
		label {
			z-index: 10;
		}
	}
	
	td.grade-breakdown-input-column {
		padding-top: .6rem;
		
		label {
			padding-top: .5rem;
		}
	}
	
	.grade-breakdown-label-column {
		width: 120px;
		
		label {
			color: $text-grey;
			margin: 0;
			padding: 0;
		}
	}
	
	.grade-breakdown-link-column {
		width: 76px;
	}
	
	.form-row {
		input[type=submit], button {
			margin-top: .5rem;
		}
	}
}