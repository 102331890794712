.header {
	position: fixed;
	box-shadow: 0 0 5px $border-grey;
	width: 100%;
	padding: 1rem 0 .5rem;
	background: #FFF;
	z-index: 10;
	top: 0;
	
	.site-nav, h1, .control {
		float: left;
	}
	
	.control {
		padding-bottom: .1rem;
		margin-top: -.15rem;
	}
	
	.site-nav {
		padding-left: 4vw;
	}
	
	.button, button, .document-options {
		float: right;
	}
	
	h1 {
		font-size: 3em;
		line-height: 1;
		margin: -1px 0;
		padding-left: 1.5rem;
	}
	
	.logo {
		@include accelerated;
		
		padding-left: 2.7rem;
		position: relative;
		
		a {
			text-decoration: none;
		}
		
		&::before {
			@extend .grade-icon;
			@extend .c;
			
			content: "A";
			position: absolute;
			left: 3px;
			top: 8px;
			line-height: 30px;
		}
		
		&::after {
			content: "+";
			position: absolute;
			font-weight: bold;
			font-family: Impact, "Trebuchet MS", sans-serif;
			left: 22px;
			top: 8px;
			font-size: 1rem;
			color: $text-default;
		}
	}
}

%below-header {
	padding-top: 70px;
}