$blue: #6B899F;
$red: #C6323C;
$green: #99BD33;
$yellow: #F9BD36;
$orange: #FF8500;

$blue-highlight: lighten($blue, 30%);

.blue   {color: $blue   !important}
.red    {color: $red    !important}
.green  {color: $green  !important}
.yellow {color: $yellow !important}

$light-grey: #EAEAEA;
$text-grey: #666;
$text-default: #333;
$text-light-grey: #999;
$border-grey: darken($light-grey, 10%);

$fade-out: rgba(255, 255, 255, 0.7);

%chalkboard {
	background: {
		color: #333;
		size: 400px;
		image: url(../images/blackboard_repeating.jpg);
	}
}