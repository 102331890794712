$font-league: league-gothic, "Trebuchet MS";
$font-proxima: proxima-nova, sans-serif;

.font-league {font-family: $font-league}
.font-proxima {font-family: $font-proxima}

body {
	font-family: $font-proxima;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-league;
	font-weight: normal;
	margin: 1.5rem 0 0;
	
	&:first-child {
		margin-top: 0;
	}
}

h1 {
	font-size: 2.4em;
	text-transform: uppercase;

	span {
		text-transform: none;
	}
}

h2 {
	font-size: 2em;
}

h3 {
	text-transform: uppercase;
	font-size: 1.6em
}

h4 {
	font-size: 1.4em;
}