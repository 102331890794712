.popup-wrapper {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	text-align: center;
	background: $fade-out;
	z-index: 100;
	
	&:before {
		content: "";
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
}

.popup {
	display: inline-block;
	vertical-align: middle;
	width: 700px;
	max-width: 90%;
	max-height: 90%;
	z-index: 110;
	overflow: auto;
	border-radius: .4em;
	box-shadow: 0 0 5px #777;
	background: #FFF;
	
	h2 {
		margin-top: 0;
	}
}

.popup-content {
	text-align: left;
	position: relative;
	overflow: auto;
	padding-bottom: calc(3rem + 8px);
	
	.cancel {
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
	}
}

.stripe-wrapper {
	left: 1.5rem;
	right: 1.5rem;
	position: absolute;
	bottom: 0;
}

.stripe-wrapper, .stripe {
	&:before, &:after {
		content: "";
		width: 25%;
		position: absolute;
		bottom: 1.5rem;
		left: 0;
		padding: 0;
		height: 8px;
	}
}

.stripe-wrapper {
	&:before {
		left: 0;
		background: $green;
	}
	
	&:after {
		left: 25%;
		background: $blue;
	}
}

.stripe {
	&:before {
		left: 50%;
		background: $yellow;
	}
	
	&:after {
		left: 75%;
		background: $red;
	}
}