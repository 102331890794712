.app-wrapper {
	overflow: hidden;
	
	.fixed-header {
		position: fixed;
		height: 62px;
		top: 8px;
		width: calc(100% - 100px);
		padding: .5rem .75rem .5rem;
		background: #FFF;
		z-index: 20;
		box-shadow: .5rem 5px 5px -5px $border-grey;
	}
	
	.full-content {
		overflow: visible;
	}
	
	.secondary-header {
		overflow: auto;
		padding: .75rem 1.5rem .3rem;
		background: #FFF;
		height: 600px;
	}
	
	.fixed-header .primary {
		float: right;
	}
	
	.below-fixed-header {
		margin-top: 68px;
	}
	
	.below-secondary-header {
		margin-top: 145px;
	}
	
	// Overrides to fit with the fixed side nav
	.scroll-columns {
		bottom: 28px; // Height of bottom copyright bar
		left: 100px;
	}
}

// Wrapper components
.app-main {
	width: 100%;
	padding: .5rem 0 0 100px;
	z-index: 10;
}

.app-nav {
	@extend %chalkboard;
	
	box-shadow: inset -15px 0 15px -15px #000;
	width: 100px;
	height: 100%;
	position: fixed;
	z-index: 30;
	padding: calc(.5rem + 8px) .5rem .5rem;
	
	a {
		color: #FFF;
		text-decoration: none;
		text-align: center;
		display: block;
		font-size: .9em;
		height: 90px;
		padding-top: 70px;
		background: {
			size: 58px;
			position: center 14px;
			repeat: no-repeat;
		}
	}
	
	.arrow {
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 4px;
		transition: .15s;
		transform: translate(0, 0);
		
		&.position-1 {transform: translate(0, 0)}
		&.position-2 {transform: translate(0, 90px)}
		&.position-3 {transform: translate(0, 90px * 2)}
		&.position-4 {transform: translate(0, 90px * 3)}
		&.position-5 {transform: translate(0, 90px * 4)}
		&.position-6 {transform: translate(0, 90px * 5)}
	}
	
	.arrow:before {
		content: "";
		display: block;
		border: {
			top: 10px solid transparent;
			bottom: 10px solid transparent;
			right: 10px solid #FFF;
		}
		position: relative;
		top: 45px;
	}
	
	.settings-icon {
		background-image: url(../images/gear.svg);
	}
	
	.reports-icon {
		background-image: url(../images/reports.svg);
		background-size: 47px;
		background-position: center 17px;
	}
	
	.planner-icon {
		background-image: url(../images/planner.svg);
		background-size: 42px;
		background-position: center 20px;
	}
	
	.activities-icon {
		background-image: url(../images/activities.svg);
		background-size: 45px;
		background-position: center 20px;
	}
	
	.classes-icon {
		background-image: url(../images/classes.svg);
		background-size: 44px;
		background-position: center 20px;
	}
}

.app-footer {
	width: 100%;
	position: fixed;
	bottom: 0;
	margin-left: 100px;
	background: #FFF;
	padding: .1rem calc(100px + .75rem) 0 .75rem;
	box-shadow: 1rem 5px 5px 5px $border-grey;
	z-index: 10;
	
	.copyright {
		font-size: .9rem;
		margin-top: 2px;
	}
	
	.pipe-links {
		float: right;
		padding: .1rem 0 .35rem;
	}
}

.login-info {
	@extend blockquote;
	
	font-style: normal;
	padding: .8rem 1rem 1.6rem;
	
	ul {
		padding-left: 1rem;
		list-style: none;
		text-indent: -5px;
	
		li::before {
			content: "-";
			position: relative;
			left: -5px;
		}
	}
}