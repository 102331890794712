.date-picker {
	width: 248px;
	
	input[type=text], input[type=date] {
		width: 70%;
	}
	
	.clear-input {
		float: right;
		top: .4rem;
	}
}

.date-picker .Zebra_DatePicker {
	td, th {
		text-align: center;
		padding: .25rem;
		min-height: 0;
		background: #FFF !important;
	}
	
	.dp_daypicker {
		margin-bottom: 0;
		
		td, th {
			width: 14.28%;
			transition: all .3s;
		}
		
		td:not(.dp_not_in_month) {
			cursor: pointer;
			
			&:hover {
				background: $blue !important;
				color: #FFF;
			}
		}
	}
	
	.dp_monthpicker {
		margin-bottom: 0;
		width: 100% !important;
		height: auto !important;
		
		td {
			width: 33.33%;
			cursor: pointer;
			transition: all .3s;
			
			&:hover {
				background: $blue !important;
				color: #FFF;
			}
		}
	}
	
	.dp_header, .dp_footer {
		width: 100% !important;
		margin-bottom: 0;
		
		tr {
			width: 100%;
		}
		
		td {
			cursor: pointer;
			border: 0;
		}
	}
	
	.dp_header {
		margin-top: 1rem;
	}
	
	.dp_footer {
		margin-bottom: -.25rem;
		
		td {
			text-decoration: underline;
		}
		
		.dp_today {
			text-align: left;
		}
		
		.dp_clear {
			text-align: right;
		}
	}
	
	.dp_selected {
		background: $blue !important;
		color: #FFF;
	}
	
	.dp_not_in_month {
		color: $text-light-grey;
		cursor: default;
		
		&:hover {
			color: $text-grey;
			background: #FFF;
		}
	}
	
	.dp_caption {
		width: 70%;
	}
	
	.dp_next, .dp_previous {
		width: 15%;
		font-size: 20px;
		padding-top: 0;
	}
}