.listing {
	clear: both;
	
	table {
		display: block;
		margin-bottom: 1rem;
	}
	
	tbody, thead {
		display: block;
	}
	
	tr {
		display: flex;
	}
	
	td, th {
		display: inline-block;
		padding-top: 1.05rem;
		min-height: 55px;
		height: auto;
		display: inline-block;
		position: relative;
	}
	
	th {
		font-weight: normal;
		padding: 0 .5em .1em;
		min-height: 19px;
		font-size: .9em;
	}
	
	td {
		transition: all .3s;
		border-top: 1px solid $border-grey;
		transition: max-width .2s;
	}
	
	h2 {
		margin-bottom: -1.2rem;
		padding-bottom: 0;
	}
	
	.hard-due {
		font-weight: bold;
	}
	
	.soft-due::after {
		content: "*";
	}
	
	tr:nth-child(odd) td {
		background: $light-grey;
	}
	
	td.credits-column, td.type-column {
		color: $text-grey;
	}
	
	th.icons-column {
		padding-left: .6rem;
	}
	
	.credits-column {
		width: 102px;
	}
	
	.type-column {
		width: 220px;
	}
	
	.icons-column {
		width: 150px;
	}
	
	.form-column {
		padding-top: .3rem;
		padding-bottom: 1rem;
		flex-grow: 1;
		
		textarea {
			margin-top: .3rem;
		}
	}
	
	td.icons-column {
		padding-top: .3rem;
		padding-bottom: 0;
	}
	
	.name-column {
		flex-grow: 1;
	}
	
	tr.selected td {
		background: $blue-highlight;
		border-color: $blue;
	}
	
	tr.selected + tr td {
		border-top-color: $blue;
	}
	
	tr:last-child td {
		border-bottom: 1px solid $border-grey;
	}
	
	tr.selected:last-child td {
		border-bottom-color: $blue;
	}
	
	.grade-column {
		padding: .8rem 0 0;
		width: 44px;
	}
	
	.lesson-grade label {
		padding-top: .5rem;
	}
	
	.grade-icon {
		margin-left: 11px;
	}
	
	.circle-icon {
		margin: .15rem .1rem 0;
	}
	
	.edit-column {
		padding-top: 0;
		padding-bottom: 0;
		width: 30px;
	}
	
	.edit-icon {
		text-indent: -99999px;
		display: block;
		height: 53px;
		width: 15px;
		background: {
			image: url('../images/arrow.svg');
			size: 12px;
			position: 0 12px;
			repeat: no-repeat;
		}
	}
}

.classes, .activities, .lessons {
	@include accelerated();
	
	overflow: hidden;
}

.classes, .activities, .reports {
	td {
		cursor: pointer;
	}
}

.activity-data {
	.text-content {
		flex-grow: 0;
		width: 50%;
		padding-bottom: .5rem;
		
		p {
			padding-top: 0;
		}
	}
}

.lessons {
	padding-bottom: 21rem;
	
	.lesson-name-class {
		padding-top: .6rem;
		
		.secondary {
			clear: both;
			font-size: .9rem;
			display: block;
		}
	}
	
	.name-column {
		cursor: pointer;
	}
	
	.lesson-notes {
		flex-grow: 1;
	}
	
	.lesson-grade {
		width: 110px;
	}
	
	.lesson-grade-list {
		width: 200px;
		flex-shrink: 0;
		
		input {
			padding-left: 150px;
		}
		
		.item {
			clear: both;
		}
		
		.grade-status {
			margin-top: -7px;
		}
	}
	
	input[type=checkbox] {
		margin-left: 1rem;
	}
	
	.grade-status {
		width: 120px;
		display: block;
		clear: both;
	}
	
	.lesson-edit {
		padding-right: 1rem;
	}
	
	.lesson-annex {
		display: none;
		position: relative;
		
		input[type=text], textarea {
			position: relative;
			z-index: 12;
			margin: .3rem 0 .5rem;
		}
		
		label {
			z-index: 14;
		}
		
		.error {
			padding-top: 0;
			margin-top: -.3rem;
		}
		
		p {
			z-index: 3;
			padding-top: 0;
			position: relative;
		}
		
		&:nth-child(odd) td {
			background: #FFF;
		}
		
		td {
			padding-top: .5rem;
			padding-bottom: 2rem;
		}
		
		&::before {
			content: "";
			height: 20px;
			width: 20px;
			border: 1px solid $border-grey;
			background: #FFF;
			border-radius: 3px;
			position: absolute;
			transform: rotate(45deg);
			left: 50%;
			margin-left: -10px;
			top: -8px;
			z-index: 1;
		}
		
		&::after {
			content: "";
			height: 25px;
			width: 50px;
			position: absolute;
			left: 50%;
			top: 1px;
			background: #FFF;
			margin-left: -25px;
			z-index: 10;
		}
	}
	
	.is-expanded {
		display: flex;
	}
	
	.lesson-annex td {
		height: auto;
	}
	
	.lesson-form-inputs {
		flex-grow: 1;
		
		input[type=submit] {
			margin-right: .5rem;
		}
	}
	
	.reorder-hidden {
		display: none;
	}
	
	.lesson-order {
		padding-top: .6rem;
	}
	
	.lesson-grade-input {
		padding-top: .6rem;
		
		input[type=text] {
			width: 95px;
			padding-left: 60px;
		}
	}
}

.planner-lessons {
	&.planner-lessons {
		padding-top: 0;
	}
	
	.lesson-type {
		display: none;
	}
	
	.lesson-notes {
		width: 100%;
	}
	
	.lesson-grade-list {
		display: none;
	}
	
	table {
		width: 100%;
		clear: both;
	}
	
	.top-row {
		padding-top: 4px;
		
		.secondary {
			float: left;
			margin-top: -5px;
			position: relative;
			top: 8px;
		}
		
		.lesson-name-text {
			clear: both;
			position: relative;
			top: 8px;
		}
	}
	
	.circle-icon {
		margin-right: .4rem;
		position: relative;
		bottom: -.8rem;
	}
	
	h2 {
		float: left;
	}
}

.s-reorder {
	.reorder-hidden {
		display: block;
	}
	
	td.reorder-hidden, th.reorder-hidden {
		display: table-cell;
	}
}

.s-reorder .reorder-visible {
	display: none;
}

.lesson-order {
	width: 55px;
	flex-shrink: 0;
	
	input {
		width: 45px;
	}
}

.listing-controls {
	.control {
		float: left;
		margin-top: -.4rem;
	}
	
	.button, input[type=text], button {
		float: right;
	}
}

.is-panel-open {
	.panel-hidden {
		opacity: 0;
		z-index: 0;
	}
	
	.panel-visible {
		opacity: 1;
		z-index: 1;
	}
}

.student-icons {
	height: calc(53px - .6rem);
	overflow: hidden;
}

.panel-back {
	@include accelerated;
	
	width: 0;
	float: left;
	transition: all .2s;
	opacity: 0;
	padding-left: 0;
	
	.is-panel-open & {
		width: 100px;
		opacity: 1;
		padding-left: 2.75rem;
	}
}

.panel-action {
	z-index: 10;
	right: 1.5rem;
	top: .5rem;
	position: absolute;
	transition: all .3s;
}

.panel-visible {
	opacity: 0;
	z-index: 0;
}

.panel-wrapper {
	transition: all .3s;
	transform: translate(100%);
	position: absolute;
	top: 0;
	left: calc(25% + 5px);
	margin: 0 -1.5rem;
	padding-left: 1.5rem;
	height: 100%;
	width: calc(75% + 1.5rem - 5px);
	background: #FFF;
	
	.panel {
		padding: 1.5rem;
		box-shadow: -5px 1rem 5px -5px $border-grey;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		width: 100%;
		height: 100%;
	}
	
	.is-panel-open & {
		transform: translate(0);
	}
}