.blue {
  color: #6B899F !important; }

.red {
  color: #C6323C !important; }

.green {
  color: #99BD33 !important; }

.yellow {
  color: #F9BD36 !important; }

.main-section, .app-nav, .document-content {
  background-color: #333;
  background-size: 400px;
  background-image: url(../images/blackboard_repeating.jpg); }

.font-league {
  font-family: league-gothic, "Trebuchet MS"; }

.font-proxima {
  font-family: proxima-nova, sans-serif; }

body {
  font-family: proxima-nova, sans-serif; }

h1, h2, .tabs a, .report-info h3, h3, h4, h5, h6 {
  font-family: league-gothic, "Trebuchet MS";
  font-weight: normal;
  margin: 1.5rem 0 0; }
  h1:first-child, h2:first-child, .tabs a:first-child, .report-info h3:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0; }

h1 {
  font-size: 2.4em;
  text-transform: uppercase; }
  h1 span {
    text-transform: none; }

h2, .tabs a, .report-info h3 {
  font-size: 2em; }

h3 {
  text-transform: uppercase;
  font-size: 1.6em; }

h4 {
  font-size: 1.4em; }

p {
  padding: .4em 0; }

article ul, article ol {
  padding-left: 2em; }
  article ul li, article ol li {
    margin-bottom: 2px; }

a {
  color: inherit; }

img {
  max-width: 100%; }

hr {
  border: 0;
  height: 1px;
  background: #d1d1d1;
  margin: 1.5rem 0 .5rem; }

blockquote, .login-info {
  background: #EAEAEA;
  margin: .75rem 0 1.5rem;
  padding: .15rem .75rem;
  font-style: italic;
  border: 1px solid #d1d1d1; }
  blockquote footer, .login-info footer {
    margin-bottom: .3rem; }
  blockquote cite, .login-info cite {
    font-style: normal; }
    blockquote cite::before, .login-info cite::before {
      content: "- "; }

.splash {
  font-size: 2.7rem;
  padding-bottom: 0; }

.right {
  float: right !important; }

.left {
  float: left !important; }

.center {
  text-align: center; }

.notes {
  color: #666; }

.clear {
  overflow: auto; }

.secondary {
  color: #666;
  text-decoration: none; }
  h1 .secondary {
    color: #999;
    margin-left: .2em; }

.animated {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000; }

.text-uppercase {
  text-transform: uppercase; }

.text-lowercase {
  text-transform: lowercase; }

.text-capitalize {
  text-transform: capitalize; }

.hidden, .is-hidden {
  display: none !important; }

.visually-hidden {
  visibility: hidden !important; }

.visible {
  display: block !important; }

.row {
  padding: 1.5rem .75rem; }
  .row.full {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }

.row + .row {
  padding-top: 0; }

.link {
  color: #6B899F;
  text-decoration: underline; }

.overflow-visible {
  overflow: visible !important; }

.overflow-hidden {
  overflow: hidden !important; }

.scroll-columns {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  margin: 0;
  padding: 0 .75rem;
  overflow: hidden; }
  .scroll-columns > .col-1, .scroll-columns > .col-2, .scroll-columns > .col-3, .scroll-columns > .col-4, .scroll-columns > .app-nav:before, .scroll-columns > .app-nav:after, .scroll-columns > .app-main:before, .scroll-columns > .app-main:after, .scroll-columns > .header:before, .scroll-columns > .header:after, .scroll-columns > .container:before, .scroll-columns > .container:after, .scroll-columns > .col-5, .scroll-columns > .col-6, .scroll-columns > .col-7, .scroll-columns > .col-8, .scroll-columns > .col-9, .scroll-columns > .col-10, .scroll-columns > .col-11, .scroll-columns > .col-12 {
    overflow: auto;
    height: 100%;
    padding: 1.5rem .75rem;
    -webkit-overflow-scrolling: touch; }

.col-1 {
  width: 8.33333%;
  min-height: 1px; }

.col-2 {
  width: 16.66667%;
  min-height: 1px; }

.col-3 {
  width: 25%;
  min-height: 1px; }

.col-4, .app-nav:before, .app-nav:after, .app-main:before, .app-main:after, .header:before, .header:after, .container:before, .container:after {
  width: 33.33333%;
  min-height: 1px; }

.col-5 {
  width: 41.66667%;
  min-height: 1px; }

.col-6 {
  width: 50%;
  min-height: 1px; }

.col-7 {
  width: 58.33333%;
  min-height: 1px; }

.col-8 {
  width: 66.66667%;
  min-height: 1px; }

.col-9 {
  width: 75%;
  min-height: 1px; }

.col-10 {
  width: 83.33333%;
  min-height: 1px; }

.col-11 {
  width: 91.66667%;
  min-height: 1px; }

.col-12 {
  width: 100%;
  min-height: 1px; }

.col-1, .col-2, .col-3, .col-4, .app-nav:before, .app-nav:after, .app-main:before, .app-main:after, .header:before, .header:after, .container:before, .container:after, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  float: left;
  padding: 0 .75rem; }
  .col-1.animated, .col-2.animated, .col-3.animated, .col-4.animated, .animated.app-nav:before, .animated.app-nav:after, .animated.app-main:before, .animated.app-main:after, .animated.header:before, .animated.header:after, .animated.container:before, .animated.container:after, .col-5.animated, .col-6.animated, .col-7.animated, .col-8.animated, .col-9.animated, .col-10.animated, .col-11.animated, .col-12.animated {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    transition: width .3s; }
  .col-1.right, .col-2.right, .col-3.right, .col-4.right, .right.app-nav:before, .right.app-nav:after, .right.app-main:before, .right.app-main:after, .right.header:before, .right.header:after, .right.container:before, .right.container:after, .col-5.right, .col-6.right, .col-7.right, .col-8.right, .col-9.right, .col-10.right, .col-11.right, .col-12.right {
    float: right; }
  .col-1.first, .col-2.first, .col-3.first, .col-4.first, .first.app-nav:before, .first.app-nav:after, .first.app-main:before, .first.app-main:after, .first.header:before, .first.header:after, .first.container:before, .first.container:after, .col-5.first, .col-6.first, .col-7.first, .col-8.first, .col-9.first, .col-10.first, .col-11.first, .col-12.first {
    padding-left: 0; }
  .col-1.last, .col-2.last, .col-3.last, .col-4.last, .last.app-nav:before, .last.app-nav:after, .last.app-main:before, .last.app-main:after, .last.header:before, .last.header:after, .last.container:before, .last.container:after, .col-5.last, .col-6.last, .col-7.last, .col-8.last, .col-9.last, .col-10.last, .col-11.last, .col-12.last {
    padding-right: 0; }

.col-fixed {
  position: fixed;
  width: 275px;
  padding-right: 1.5rem; }
  @media screen and (max-width: 1100px) {
    .col-fixed {
      width: 25%; } }

.col-shadow {
  float: right;
  box-shadow: -5px 1rem 5px -5px #d1d1d1; }

.row .col-bordered-right {
  padding-right: 1.5rem; }
.row .col-bordered-left {
  padding-left: 1.5rem; }
.row .col-bordered {
  padding-right: 1.5rem;
  padding-left: .75rem; }

html {
  box-sizing: border-box; }

body {
  -webkit-font-smoothing: antialiased; }

*, *:before, *:after {
  box-sizing: inherit; }

* {
  margin: 0;
  padding: 0; }

.pipe-links {
  font-size: .9rem; }
  .pipe-links a {
    padding: 0 .25em;
    text-decoration: none; }
    .pipe-links a:hover {
      text-decoration: underline; }

.container {
  max-width: 1100px;
  padding: 0 1.5rem;
  margin: 0 auto; }
  .container > .row {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }

@keyframes fadein {
  from {
    opacity: 0; }

  to {
    opacity: 1; } }

.is-loading {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
  animation: fadein .2s;
  background-color: rgba(255, 255, 255, 0.7);
  background-image: url(../images/loading.gif);
  background-position: center;
  background-background-size: 50%;
  background-repeat: no-repeat; }
  .panel-wrapper .is-loading {
    margin-left: -1.5rem; }
  #sq-context-app-main > .is-loading {
    margin-top: .5rem;
    width: calc(100% - 100px); }
  .full-content > .is-loading {
    margin-top: .5rem; }

.circle-icon {
  height: 40px;
  width: 40px;
  float: left;
  border-radius: 50%;
  color: #FFF;
  font-weight: normal;
  text-align: center;
  padding-top: .65em;
  background-color: #333;
  background-position: center;
  background-size: cover; }
  .circle-icon.all {
    font-weight: bold; }

.students-count {
  display: block;
  padding: .75rem .1rem; }

input, textarea, select {
  font-family: proxima-nova, sans-serif; }

.form-row {
  clear: both; }

input[type=text], input[type=password], input[type=date], input[type=email], select, textarea, .field {
  height: 35px;
  font-size: 1rem;
  border: 1px solid #d1d1d1;
  width: 100%;
  padding-left: .5rem; }
  input[type=text].inline, input[type=password].inline, input[type=date].inline, input[type=email].inline, select.inline, textarea.inline, .field.inline {
    padding-left: 6rem;
    margin-top: 1.5rem; }
    td input[type=text].inline, td input[type=password].inline, td input[type=date].inline, td input[type=email].inline, td select.inline, td textarea.inline, td .field.inline {
      margin: 0; }
  .first input[type=text].inline, .first input[type=password].inline, .first input[type=date].inline, .first input[type=email].inline, .first select.inline, .first textarea.inline, .first .field.inline {
    margin-top: .5rem; }
  input[type=text]:focus, input[type=password]:focus, input[type=date]:focus, input[type=email]:focus, select:focus, textarea:focus, .field:focus {
    outline: none;
    border: 1px solid #6B899F; }
  input[type=text].error, input[type=password].error, input[type=date].error, input[type=email].error, select.error, textarea.error, .field.error {
    border: 1px solid #C6323C; }
  input[type=text].valid, input[type=password].valid, input[type=date].valid, input[type=email].valid, select.valid, textarea.valid, .field.valid {
    border: 1px solid #99BD33; }
  input[type=text]:disabled, input[type=password]:disabled, input[type=date]:disabled, input[type=email]:disabled, select:disabled, textarea:disabled, .field:disabled {
    opacity: .8; }

td select {
  height: 25px;
  background: transparent;
  width: 120px;
  margin-top: -3px; }

textarea {
  height: 150px;
  padding: .45rem;
  resize: vertical; }

input[type=radio], input[type=checkbox] {
  float: left;
  cursor: pointer;
  margin: 1rem 0 .4rem; }

input[type=text], input[type=password], input[type=date], input[type=email], textarea, .field {
  border-radius: .4rem;
  box-shadow: inset 0 0 4px #999;
  -webkit-appearance: none; }
  input[type=text]:focus, input[type=password]:focus, input[type=date]:focus, input[type=email]:focus, textarea:focus, .field:focus {
    box-shadow: inset 0 0 4px #6B899F; }
  input[type=text].error, input[type=password].error, input[type=date].error, input[type=email].error, textarea.error, .field.error {
    box-shadow: inset 0 0 4px #C6323C; }
  input[type=text].valid, input[type=password].valid, input[type=date].valid, input[type=email].valid, textarea.valid, .field.valid {
    box-shadow: inset 0 0 4px #99BD33; }

label, .label, .schedule .hour-label {
  padding: .5rem 0 .1rem;
  display: block;
  cursor: pointer;
  color: #666; }
  label.checkbox, .label.checkbox, .schedule .checkbox.hour-label {
    float: left;
    padding-left: .25em;
    margin-top: .4em;
    position: relative;
    top: -6px; }
  label.inline, .label.inline, .schedule .inline.hour-label {
    position: absolute;
    padding: 2rem .5em;
    z-index: 3; }
    td label.inline, td .label.inline, td .schedule .inline.hour-label, .schedule td .inline.hour-label {
      padding-top: .8rem; }
  label.error, .label.error, .schedule .error.hour-label {
    color: #C6323C;
    padding-top: .2rem; }
  .first label.inline, .first .label.inline, .first .schedule .inline.hour-label, .schedule .first .inline.hour-label {
    padding-top: 1rem; }

.after-input {
  display: inline-block;
  vertical-align: top;
  margin-top: 7px; }
  .after-input a {
    display: block;
    padding-left: .5rem; }

.reveal-input, .clear-input {
  position: absolute;
  right: .75rem;
  top: 2.3rem; }

.reveal-input {
  opacity: 0;
  transition: opacity .2s; }
  .reveal-input.is-active {
    opacity: 1; }

input[type=checkbox] {
  width: auto;
  margin-top: 12px;
  display: inline; }

.form-row {
  position: relative; }
  .form-row.auto-width input, .form-row.auto-width select {
    width: auto; }
  .form-row .button, .form-row button, .form-row input[type=submit] {
    margin-top: 1.5rem; }
    .form-row .button.secondary, .form-row button.secondary, .form-row input[type=submit].secondary {
      float: right; }

.form-inline {
  float: left;
  padding-right: .75rem; }

.grade-box {
  width: 200px;
  text-transform: uppercase; }

input[type=file] {
  padding-bottom: .95rem; }

.sq-input-image-current {
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 32px;
  float: left;
  margin-right: .5rem; }
  .sq-input-image-current span {
    height: 64px;
    width: 64px;
    background-size: cover;
    background-position: center;
    display: block; }
  .sq-input-image-current img {
    display: none; }

.terms-checkbox {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  overflow: auto;
  margin-top: 1rem; }
  .terms-checkbox input[type=checkbox] {
    position: absolute;
    left: 0;
    top: 1px; }
  .terms-checkbox label {
    padding-left: 1.2rem; }
  .terms-checkbox .error {
    padding-bottom: .5rem; }

.grading-method {
  margin: -1.4rem 0 1rem; }
  .grading-method label, .grading-method input {
    display: inline-block;
    padding-top: .75rem; }
  .grading-method input {
    margin-right: .7rem; }
  .grading-method .grade-icon, .grading-method .header .logo::before, .header .grading-method .logo::before {
    top: -.3rem;
    margin-right: .5rem; }
  .grading-method div {
    width: 140px;
    float: left; }

.attendance-method div {
  width: 140px;
  float: left; }

.time-picker {
  position: relative;
  overflow: auto;
  clear: both; }
  .time-picker:before {
    content: ":";
    position: absolute;
    top: 2.2rem;
    left: 1.85rem; }
  .time-picker input[type=text] {
    position: absolute;
    top: 1.8rem;
    padding: 0;
    border: 0;
    box-shadow: none;
    width: 1.8em;
    background: none; }
    .time-picker input[type=text]:focus {
      border: 0;
      box-shadow: none; }
  .time-picker .minutes {
    left: 2.5rem; }
  .time-picker .prefix {
    right: .35rem;
    position: absolute;
    margin: 0;
    top: 2.15rem;
    height: auto;
    width: auto; }

fieldset {
  border: 0;
  margin-bottom: .5rem; }

legend {
  color: #777; }

.checkbox-group {
  margin-top: .6rem; }
  .checkbox-group .form-row {
    overflow: auto;
    border-bottom: 1px solid #d1d1d1; }
    .checkbox-group .form-row:first-of-type {
      border-top: 1px solid #d1d1d1; }
  .checkbox-group select {
    padding: .2rem .3rem;
    margin-top: .4rem;
    height: auto;
    width: 130px; }
  .checkbox-group .error {
    float: right;
    clear: both; }
  .checkbox-group .grade-level {
    transition: all .2s;
    float: right;
    opacity: 0;
    visibility: hidden; }
  .checkbox-group :checked + .grade-level {
    opacity: 1;
    visibility: visible; }

.attendance-form input[type=text] {
  width: 2em; }

table {
  border-collapse: collapse;
  width: 100%; }
  table th, table td {
    padding: .5em; }
  table th {
    text-align: left; }
  table .thead-hidden th {
    height: 1px;
    overflow: hidden;
    padding: 0; }

button, .button, input[type=submit] {
  background: #F9BD36;
  border: 0;
  padding: .35rem 1.2rem;
  height: 46px;
  font-family: league-gothic, "Trebuchet MS";
  font-size: 1.8rem;
  border-radius: .5rem;
  text-decoration: none;
  border: 1px solid #f5ac07;
  box-shadow: 0 1px 3px #d1d1d1;
  cursor: pointer;
  display: inline-block;
  color: inherit;
  background-image: -webkit-linear-gradient(to bottom, #FED93C, #F7A502);
  background-image: linear-gradient(to bottom, #FED93C, #F7A502); }
  button:hover, .button:hover, input[type=submit]:hover {
    background-image: -webkit-linear-gradient(to bottom, #fed528, #e39702);
    background-image: linear-gradient(to bottom, #fed528, #e39702); }
  button:active, .button:active, input[type=submit]:active {
    background-image: -webkit-linear-gradient(to bottom, #e39702, #fed528);
    background-image: linear-gradient(to bottom, #e39702, #fed528); }
  button.secondary, .button.secondary, input[type=submit].secondary {
    border-color: #CCC;
    color: #333;
    background-image: -webkit-linear-gradient(to bottom, #F8F8F8, #DADADA);
    background-image: linear-gradient(to bottom, #F8F8F8, #DADADA); }
    button.secondary:hover, .button.secondary:hover, input[type=submit].secondary:hover {
      background-image: -webkit-linear-gradient(to bottom, #eeeeee, #d0d0d0);
      background-image: linear-gradient(to bottom, #eeeeee, #d0d0d0); }
    button.secondary:active, .button.secondary:active, input[type=submit].secondary:active {
      background-image: -webkit-linear-gradient(to bottom, #d0d0d0, #eeeeee);
      background-image: linear-gradient(to bottom, #d0d0d0, #eeeeee); }
  button.small, .button.small, input[type=submit].small {
    padding: 0.1rem 0.75rem;
    font-size: 1.6em;
    height: 35px;
    margin-top: -7px; }

.control {
  display: block;
  text-decoration: none;
  padding: 1rem 0 1rem 2.75rem;
  position: relative;
  z-index: 10;
  color: #666; }
  .control:before {
    font-family: Arial;
    height: 40px;
    width: 40px;
    font-weight: bold;
    color: #999;
    border: 4px solid #999;
    border-radius: 20px;
    overflow: hidden;
    display: block;
    position: absolute;
    font-size: 2rem;
    line-height: 34px;
    text-align: center;
    left: 0;
    top: .31rem; }
  .control.back:before {
    content: "<";
    padding-right: .2rem; }
  .control.add:before {
    content: "+"; }

.menu {
  float: left;
  padding: 2px;
  border: 1px solid #d1d1d1;
  border-radius: .5rem;
  max-height: 47px;
  overflow: hidden;
  background: #FFF;
  transition: max-height linear .2s;
  cursor: pointer;
  position: relative;
  z-index: 10; }
  .menu:after {
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    right: 1rem;
    top: 1.04rem;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-top: 10px solid #333; }
  .menu.open {
    max-height: 400px;
    overflow: scroll; }
    .menu.open .active {
      background: #EAEAEA; }
  .menu a, .menu .active {
    transition: all linear .15s;
    display: block;
    overflow: auto;
    padding-right: 3rem;
    border-radius: .4rem; }
    .menu a:hover, .menu .active:hover {
      background: #EAEAEA; }
  .menu a {
    margin-top: 2px; }
  .menu .label, .menu .schedule .hour-label, .schedule .menu .hour-label {
    padding: .65em .5em;
    float: left; }
  .menu .utility {
    margin-top: 6px;
    padding-top: 2px;
    border-top: 1px solid #d1d1d1; }
    .menu .utility a {
      margin-top: 0; }
    .menu .utility .label, .menu .utility .schedule .hour-label, .schedule .menu .utility .hour-label {
      padding: .2em .3em; }

.tabs {
  position: absolute;
  top: 1.1rem;
  margin: 0 auto;
  width: calc(100% - 3rem);
  text-align: center; }
  .tabs a {
    margin: 0 .25rem;
    padding: 0;
    line-height: 85%;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none; }
    .tabs a.active {
      border-bottom: 4px solid #333; }

.grade-icon, .header .logo::before {
  display: inline-block;
  height: 30px;
  width: 30px;
  border: 1px solid #FFF;
  outline: 3px solid #AAA;
  color: #333;
  font-family: league-gothic, "Trebuchet MS";
  font-size: 30px;
  vertical-align: top;
  overflow: hidden;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background: #AAA;
  position: relative; }
  .grade-icon.n-a, .header .n-a.logo::before {
    font-size: 1.3rem; }
  .grade-icon.a, .header .a.logo::before {
    background: #99BD33;
    outline-color: #99BD33; }
  .grade-icon.b, .header .b.logo::before {
    background: #6B899F;
    outline-color: #6B899F; }
  .grade-icon.c, .header .logo::before {
    background: #F9BD36;
    outline-color: #F9BD36; }
  .grade-icon.d, .header .d.logo::before {
    background: #FF8500;
    outline-color: #FF8500; }
  .grade-icon.f, .header .f.logo::before {
    background: #C6323C;
    outline-color: #C6323C; }
  .grade-icon sup, .header .logo::before sup {
    font-size: 18px;
    position: relative;
    line-height: 30px;
    vertical-align: top;
    top: -.45em;
    left: 1px;
    font-weight: bold;
    font-family: Impact, "Trebuchet MS", sans-serif; }

h1 .grade-icon, h1 .header .logo::before, .header h1 .logo::before {
  top: .45rem;
  margin: 0 .25rem 0 .25rem; }

.color-picker {
  margin: 0 -.2rem 0;
  overflow: hidden; }
  .color-picker .color {
    height: 2.5em;
    width: calc(16.66% - .4rem);
    margin: 0 .2rem 0;
    float: left;
    cursor: pointer; }
    .color-picker .color.active {
      background-image: url(../images/checkmark.png);
      background-size: 15px;
      background-position: center;
      background-repeat: no-repeat;
      border-width: 5px; }
  .color-picker .label, .color-picker .schedule .hour-label, .schedule .color-picker .hour-label {
    margin-left: .2rem; }

/* Yellow */
.color-0 {
  background-color: #FDDB82;
  border: 1px solid #FABE1E; }

/* Red */
.color-1 {
  background-color: #E38489;
  border: 1px solid #C83038; }

/* Green */
.color-2 {
  background-color: #C6DF7A;
  border: 1px solid #98BF21; }

/* Orange */
.color-3 {
  background-color: #FFB273;
  border: 1px solid #FF7400; }

/* Purple */
.color-4 {
  background-color: #D89CC9;
  border: 1px solid #B05799; }

/* Blue */
.color-5 {
  background-color: #8FAFC6;
  border: 1px solid #6B899F; }

/* Only for dots */
.color-6 {
  border: 1px solid #00DDDA; }

/* Cyan */
.color-7 {
  border: 1px solid #FC00CD; }

/* Pink */
.color-8 {
  border: 1px solid #007B2B; }

/* Dark Green */
.navigation {
  list-style: none; }
  .navigation a {
    display: block;
    padding: .4rem .6rem .3rem;
    text-decoration: none;
    border-left: .5rem solid #99BD33; }
    .navigation a:hover, .navigation a.is-active {
      background: #EAEAEA; }
  .navigation li:nth-child(4n+1) a {
    border-color: #99BD33; }
  .navigation li:nth-child(4n+2) a {
    border-color: #6B899F; }
  .navigation li:nth-child(4n+3) a {
    border-color: #F9BD36; }
  .navigation li:nth-child(4n+4) a {
    border-color: #C6323C; }

.app-nav:before, .app-nav:after, .app-main:before, .app-main:after, .header:before, .header:after, .container:before, .container:after {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  height: 8px; }

.app-nav:before, .container:before {
  left: 0;
  background: #99BD33; }
.app-nav:after, .container:after {
  left: 25%;
  width: 25%;
  background: #6B899F; }

.app-main:before, .header:before {
  left: 50%;
  background: #F9BD36;
  z-index: 15; }
.app-main:after, .header:after {
  left: 75%;
  background: #C6323C;
  z-index: 20; }

.sq-flash, .alert, .message {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  max-height: 0;
  border: 1px solid #FFF;
  border-radius: .15rem;
  transition: max-height .1s, padding-top .1s, padding-bottom .1s, opacity .1s;
  margin: .5rem 0;
  overflow: auto;
  clear: both;
  opacity: 0;
  padding: .4rem .6rem; }
  .sq-flash:empty, .alert:empty, .message:empty {
    margin: 0; }
  .sq-flash.success, .sq-flash.notice, .sq-flash.info, .sq-flash.warning, .sq-flash.error, .sq-flash.failure, .sq-flash.danger, .alert.success, .alert.notice, .alert.info, .alert.warning, .alert.error, .alert.failure, .alert.danger, .message.success, .message.notice, .message.info, .message.warning, .message.error, .message.failure, .message.danger {
    max-height: 60px;
    opacity: 1; }
  .sq-flash.success, .sq-flash.info, .alert.success, .alert.info, .message.success, .message.info {
    background: #C6DF7A;
    border-color: #98BF21; }
  .sq-flash.notice, .alert.notice, .message.notice {
    background: #8FAFC6;
    border-color: #6B899F; }
  .sq-flash.warning, .sq-flash.error, .alert.warning, .alert.error, .message.warning, .message.error {
    background: #FDDB82;
    border-color: #FABE1E; }
  .sq-flash.failure, .sq-flash.danger, .alert.failure, .alert.danger, .message.failure, .message.danger {
    background: #E38489;
    border-color: #C83038; }

.form-row + .message, .form-row + .sq-flash {
  margin-top: 1rem; }

.alert {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: fixed;
  top: .5rem;
  z-index: 50;
  width: 36vw;
  margin: 0;
  left: 32vw;
  border-top: 0;
  padding: .2rem 1.5rem .1rem .3rem; }

.alert-close {
  position: absolute;
  right: .3rem;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: -2px;
  user-select: none; }

.metadata h1 {
  float: left; }
.metadata .dropdown-wrapper {
  float: right; }
.metadata p {
  padding: 0 0 1rem; }

.stats-list {
  clear: both;
  list-style: none;
  padding: 1rem 0; }

.footnote {
  clear: both;
  display: block;
  color: #666; }

.date-picker {
  width: 248px; }
  .date-picker input[type=text], .date-picker input[type=date] {
    width: 70%; }
  .date-picker .clear-input {
    float: right;
    top: .4rem; }

.date-picker .Zebra_DatePicker td, .date-picker .Zebra_DatePicker th {
  text-align: center;
  padding: .25rem;
  min-height: 0;
  background: #FFF !important; }
.date-picker .Zebra_DatePicker .dp_daypicker {
  margin-bottom: 0; }
  .date-picker .Zebra_DatePicker .dp_daypicker td, .date-picker .Zebra_DatePicker .dp_daypicker th {
    width: 14.28%;
    transition: all .3s; }
  .date-picker .Zebra_DatePicker .dp_daypicker td:not(.dp_not_in_month) {
    cursor: pointer; }
    .date-picker .Zebra_DatePicker .dp_daypicker td:not(.dp_not_in_month):hover {
      background: #6B899F !important;
      color: #FFF; }
.date-picker .Zebra_DatePicker .dp_monthpicker {
  margin-bottom: 0;
  width: 100% !important;
  height: auto !important; }
  .date-picker .Zebra_DatePicker .dp_monthpicker td {
    width: 33.33%;
    cursor: pointer;
    transition: all .3s; }
    .date-picker .Zebra_DatePicker .dp_monthpicker td:hover {
      background: #6B899F !important;
      color: #FFF; }
.date-picker .Zebra_DatePicker .dp_header, .date-picker .Zebra_DatePicker .dp_footer {
  width: 100% !important;
  margin-bottom: 0; }
  .date-picker .Zebra_DatePicker .dp_header tr, .date-picker .Zebra_DatePicker .dp_footer tr {
    width: 100%; }
  .date-picker .Zebra_DatePicker .dp_header td, .date-picker .Zebra_DatePicker .dp_footer td {
    cursor: pointer;
    border: 0; }
.date-picker .Zebra_DatePicker .dp_header {
  margin-top: 1rem; }
.date-picker .Zebra_DatePicker .dp_footer {
  margin-bottom: -.25rem; }
  .date-picker .Zebra_DatePicker .dp_footer td {
    text-decoration: underline; }
  .date-picker .Zebra_DatePicker .dp_footer .dp_today {
    text-align: left; }
  .date-picker .Zebra_DatePicker .dp_footer .dp_clear {
    text-align: right; }
.date-picker .Zebra_DatePicker .dp_selected {
  background: #6B899F !important;
  color: #FFF; }
.date-picker .Zebra_DatePicker .dp_not_in_month {
  color: #999;
  cursor: default; }
  .date-picker .Zebra_DatePicker .dp_not_in_month:hover {
    color: #666;
    background: #FFF; }
.date-picker .Zebra_DatePicker .dp_caption {
  width: 70%; }
.date-picker .Zebra_DatePicker .dp_next, .date-picker .Zebra_DatePicker .dp_previous {
  width: 15%;
  font-size: 20px;
  padding-top: 0; }

.sq-pagination {
  margin: 1rem 0;
  padding: 0;
  overflow: auto;
  list-style-type: none; }
  .sq-pagination a {
    display: block;
    float: left;
    border: 1px solid #D1D1D1;
    border-right: 0;
    text-decoration: none;
    min-width: 30px;
    text-align: center;
    font-size: .9em;
    padding: .4em; }
  .sq-pagination li:last-child a {
    border-right: 1px solid #D1D1D1; }
  .sq-pagination .number.is-active a {
    background: #E9EAE9; }
  .sq-pagination .prev.is-active a, .sq-pagination .next.is-active a, .sq-pagination .first.is-active a, .sq-pagination .last.is-active a {
    color: #D1D1D1; }

.dropdown-wrapper {
  position: relative; }
  table .dropdown-wrapper {
    margin-top: 0; }

.dropdown-content {
  display: none;
  position: absolute;
  top: 2rem;
  right: -.5rem;
  padding: .5rem;
  box-shadow: 0 0 10px #999;
  z-index: 20;
  background: #FFF;
  border-radius: 9px; }
  .dropdown-content::before {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background: #FFF;
    box-shadow: 0 0 10px #999;
    transform: rotate(45deg);
    position: absolute;
    border-radius: 3px;
    right: 2rem;
    top: -.5rem; }
  .dropdown-content::after {
    content: "";
    width: 40px;
    height: 22px;
    background: #FFF;
    position: absolute;
    right: 1.5rem;
    top: 0; }
  .dropdown-content * {
    position: relative;
    z-index: 2; }

.dropdown-content.is-open {
  display: block; }

.dropdown-trigger {
  text-decoration: none;
  float: right;
  cursor: pointer;
  padding: 0 1rem;
  position: relative; }
  .dropdown-trigger::after {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border: 7px solid transparent;
    border-top: 10px solid #333;
    position: absolute;
    right: 0;
    top: calc(50% - 5px); }
    table .dropdown-trigger::after {
      right: 1rem; }
  .dropdown-trigger.is-active::after {
    border-bottom: 10px solid #333;
    border-top: 0 solid #333; }
  table .dropdown-trigger {
    padding: 0 2.1rem 0 0; }

.day-picker {
  table-layout: fixed;
  width: 320px;
  margin-bottom: 1rem; }
  .day-picker td, .day-picker th {
    width: 14.28%;
    text-align: center; }
    .day-picker td input[type=checkbox], .day-picker th input[type=checkbox] {
      margin: 0 0 0 .55rem; }
  .day-picker th {
    padding-bottom: .1rem; }
  .day-picker td {
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1; }

.popup-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100; }
  .popup-wrapper:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.popup {
  display: inline-block;
  vertical-align: middle;
  width: 700px;
  max-width: 90%;
  max-height: 90%;
  z-index: 110;
  overflow: auto;
  border-radius: .4em;
  box-shadow: 0 0 5px #777;
  background: #FFF; }
  .popup h2, .popup .tabs a, .tabs .popup a, .popup .report-info h3, .report-info .popup h3 {
    margin-top: 0; }

.popup-content {
  text-align: left;
  position: relative;
  overflow: auto;
  padding-bottom: calc(3rem + 8px); }
  .popup-content .cancel {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem; }

.stripe-wrapper {
  left: 1.5rem;
  right: 1.5rem;
  position: absolute;
  bottom: 0; }

.stripe-wrapper:before, .stripe-wrapper:after, .stripe:before, .stripe:after {
  content: "";
  width: 25%;
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  padding: 0;
  height: 8px; }

.stripe-wrapper:before {
  left: 0;
  background: #99BD33; }
.stripe-wrapper:after {
  left: 25%;
  background: #6B899F; }

.stripe:before {
  left: 50%;
  background: #F9BD36; }
.stripe:after {
  left: 75%;
  background: #C6323C; }

.listing {
  clear: both; }
  .listing table {
    display: block;
    margin-bottom: 1rem; }
  .listing tbody, .listing thead {
    display: block; }
  .listing tr {
    display: flex; }
  .listing td, .listing th {
    display: inline-block;
    padding-top: 1.05rem;
    min-height: 55px;
    height: auto;
    display: inline-block;
    position: relative; }
  .listing th {
    font-weight: normal;
    padding: 0 .5em .1em;
    min-height: 19px;
    font-size: .9em; }
  .listing td {
    transition: all .3s;
    border-top: 1px solid #d1d1d1;
    transition: max-width .2s; }
  .listing h2, .listing .tabs a, .tabs .listing a, .listing .report-info h3, .report-info .listing h3 {
    margin-bottom: -1.2rem;
    padding-bottom: 0; }
  .listing .hard-due {
    font-weight: bold; }
  .listing .soft-due::after {
    content: "*"; }
  .listing tr:nth-child(odd) td {
    background: #EAEAEA; }
  .listing td.credits-column, .listing td.type-column {
    color: #666; }
  .listing th.icons-column {
    padding-left: .6rem; }
  .listing .credits-column {
    width: 102px; }
  .listing .type-column {
    width: 220px; }
  .listing .icons-column {
    width: 150px; }
  .listing .form-column {
    padding-top: .3rem;
    padding-bottom: 1rem;
    flex-grow: 1; }
    .listing .form-column textarea {
      margin-top: .3rem; }
  .listing td.icons-column {
    padding-top: .3rem;
    padding-bottom: 0; }
  .listing .name-column {
    flex-grow: 1; }
  .listing tr.selected td {
    background: #c8d3db;
    border-color: #6B899F; }
  .listing tr.selected + tr td {
    border-top-color: #6B899F; }
  .listing tr:last-child td {
    border-bottom: 1px solid #d1d1d1; }
  .listing tr.selected:last-child td {
    border-bottom-color: #6B899F; }
  .listing .grade-column {
    padding: .8rem 0 0;
    width: 44px; }
  .listing .lesson-grade label {
    padding-top: .5rem; }
  .listing .grade-icon, .listing .header .logo::before, .header .listing .logo::before {
    margin-left: 11px; }
  .listing .circle-icon {
    margin: .15rem .1rem 0; }
  .listing .edit-column {
    padding-top: 0;
    padding-bottom: 0;
    width: 30px; }
  .listing .edit-icon {
    text-indent: -99999px;
    display: block;
    height: 53px;
    width: 15px;
    background-image: url('../images/arrow.svg');
    background-size: 12px;
    background-position: 0 12px;
    background-repeat: no-repeat; }

.classes, .activities, .lessons {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  overflow: hidden; }

.classes td, .activities td, .reports td {
  cursor: pointer; }

.activity-data .text-content {
  flex-grow: 0;
  width: 50%;
  padding-bottom: .5rem; }
  .activity-data .text-content p {
    padding-top: 0; }

.lessons {
  padding-bottom: 21rem; }
  .lessons .lesson-name-class {
    padding-top: .6rem; }
    .lessons .lesson-name-class .secondary {
      clear: both;
      font-size: .9rem;
      display: block; }
  .lessons .name-column {
    cursor: pointer; }
  .lessons .lesson-notes {
    flex-grow: 1; }
  .lessons .lesson-grade {
    width: 110px; }
  .lessons .lesson-grade-list {
    width: 200px;
    flex-shrink: 0; }
    .lessons .lesson-grade-list input {
      padding-left: 150px; }
    .lessons .lesson-grade-list .item {
      clear: both; }
    .lessons .lesson-grade-list .grade-status {
      margin-top: -7px; }
  .lessons input[type=checkbox] {
    margin-left: 1rem; }
  .lessons .grade-status {
    width: 120px;
    display: block;
    clear: both; }
  .lessons .lesson-edit {
    padding-right: 1rem; }
  .lessons .lesson-annex {
    display: none;
    position: relative; }
    .lessons .lesson-annex input[type=text], .lessons .lesson-annex textarea {
      position: relative;
      z-index: 12;
      margin: .3rem 0 .5rem; }
    .lessons .lesson-annex label {
      z-index: 14; }
    .lessons .lesson-annex .error {
      padding-top: 0;
      margin-top: -.3rem; }
    .lessons .lesson-annex p {
      z-index: 3;
      padding-top: 0;
      position: relative; }
    .lessons .lesson-annex:nth-child(odd) td {
      background: #FFF; }
    .lessons .lesson-annex td {
      padding-top: .5rem;
      padding-bottom: 2rem; }
    .lessons .lesson-annex::before {
      content: "";
      height: 20px;
      width: 20px;
      border: 1px solid #d1d1d1;
      background: #FFF;
      border-radius: 3px;
      position: absolute;
      transform: rotate(45deg);
      left: 50%;
      margin-left: -10px;
      top: -8px;
      z-index: 1; }
    .lessons .lesson-annex::after {
      content: "";
      height: 25px;
      width: 50px;
      position: absolute;
      left: 50%;
      top: 1px;
      background: #FFF;
      margin-left: -25px;
      z-index: 10; }
  .lessons .is-expanded {
    display: flex; }
  .lessons .lesson-annex td {
    height: auto; }
  .lessons .lesson-form-inputs {
    flex-grow: 1; }
    .lessons .lesson-form-inputs input[type=submit] {
      margin-right: .5rem; }
  .lessons .reorder-hidden {
    display: none; }
  .lessons .lesson-order {
    padding-top: .6rem; }
  .lessons .lesson-grade-input {
    padding-top: .6rem; }
    .lessons .lesson-grade-input input[type=text] {
      width: 95px;
      padding-left: 60px; }

.planner-lessons.planner-lessons {
  padding-top: 0; }
.planner-lessons .lesson-type {
  display: none; }
.planner-lessons .lesson-notes {
  width: 100%; }
.planner-lessons .lesson-grade-list {
  display: none; }
.planner-lessons table {
  width: 100%;
  clear: both; }
.planner-lessons .top-row {
  padding-top: 4px; }
  .planner-lessons .top-row .secondary {
    float: left;
    margin-top: -5px;
    position: relative;
    top: 8px; }
  .planner-lessons .top-row .lesson-name-text {
    clear: both;
    position: relative;
    top: 8px; }
.planner-lessons .circle-icon {
  margin-right: .4rem;
  position: relative;
  bottom: -.8rem; }
.planner-lessons h2, .planner-lessons .tabs a, .tabs .planner-lessons a, .planner-lessons .report-info h3, .report-info .planner-lessons h3 {
  float: left; }

.s-reorder .reorder-hidden {
  display: block; }
.s-reorder td.reorder-hidden, .s-reorder th.reorder-hidden {
  display: table-cell; }

.s-reorder .reorder-visible {
  display: none; }

.lesson-order {
  width: 55px;
  flex-shrink: 0; }
  .lesson-order input {
    width: 45px; }

.listing-controls .control {
  float: left;
  margin-top: -.4rem; }
.listing-controls .button, .listing-controls input[type=text], .listing-controls button {
  float: right; }

.is-panel-open .panel-hidden {
  opacity: 0;
  z-index: 0; }
.is-panel-open .panel-visible {
  opacity: 1;
  z-index: 1; }

.student-icons {
  height: calc(53px - .6rem);
  overflow: hidden; }

.panel-back {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  width: 0;
  float: left;
  transition: all .2s;
  opacity: 0;
  padding-left: 0; }
  .is-panel-open .panel-back {
    width: 100px;
    opacity: 1;
    padding-left: 2.75rem; }

.panel-action {
  z-index: 10;
  right: 1.5rem;
  top: .5rem;
  position: absolute;
  transition: all .3s; }

.panel-visible {
  opacity: 0;
  z-index: 0; }

.panel-wrapper {
  transition: all .3s;
  transform: translate(100%);
  position: absolute;
  top: 0;
  left: calc(25% + 5px);
  margin: 0 -1.5rem;
  padding-left: 1.5rem;
  height: 100%;
  width: calc(75% + 1.5rem - 5px);
  background: #FFF; }
  .panel-wrapper .panel {
    padding: 1.5rem;
    box-shadow: -5px 1rem 5px -5px #d1d1d1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%; }
  .is-panel-open .panel-wrapper {
    transform: translate(0); }

.calendar td {
  width: 14.28571%;
  height: 11vw;
  position: relative;
  padding: .25rem;
  border: 1px solid #d1d1d1; }
  .calendar td .date {
    position: absolute;
    font-size: .9em;
    top: .2rem;
    left: .2rem;
    text-decoration: none; }
.calendar th {
  font-weight: normal;
  padding: 0 0 0 .3rem; }
.calendar .inactive {
  background: #EAEAEA; }
.calendar .dots {
  position: absolute;
  top: .3rem;
  right: .3rem; }

.dot {
  float: left;
  width: 12px;
  height: 12px;
  margin-right: 1px;
  border-radius: 6px; }

.dot-label {
  float: left;
  padding-right: 1.5rem; }

.dots-legend {
  padding: 1rem 0; }
  .dots-legend .dot {
    margin-top: .25rem;
    margin-right: .2rem; }

.calendar-nav {
  position: relative; }
  .calendar-nav > h1 {
    position: absolute;
    padding-right: .7rem;
    white-space: nowrap; }
  .calendar-nav .dropdown-wrapper {
    position: absolute;
    top: .25rem;
    right: 1.5rem; }

.prev-next {
  margin: 0 auto;
  display: block;
  width: 300px;
  text-align: center;
  position: relative; }
  .prev-next .prev, .prev-next .next {
    width: 150px;
    display: block;
    padding: .75rem; }
  .prev-next .prev {
    float: left;
    text-align: right; }
  .prev-next .next {
    float: right;
    text-align: left; }
  .prev-next .today {
    position: absolute;
    top: .75rem;
    right: -4rem; }
  .prev-next .prev::before {
    content: "< "; }
  .prev-next .next::after {
    content: " >"; }

.event {
  font-size: .9em;
  border-radius: .15rem;
  padding: .1rem .2rem;
  text-decoration: none;
  display: block;
  margin: 1px 0;
  z-index: 2; }
  .event .time {
    display: block; }

.schedule.col-6 {
  padding-top: 0; }
.schedule .all-day {
  border-bottom: 1px solid #d1d1d1;
  display: flex;
  position: fixed;
  padding-bottom: 1px;
  background: #FFF;
  width: calc(50% - 5.4rem);
  z-index: 10; }
  .schedule .all-day .hour-content {
    position: relative; }
  .schedule .all-day .event {
    position: static; }
.schedule .hour {
  display: flex; }
  .schedule .hour .hour-label {
    margin-top: -15px; }
  .schedule .hour .hour-content {
    position: relative;
    height: 60px;
    border-bottom: 1px solid #d1d1d1; }
.schedule .event {
  padding: .25rem .35rem;
  margin: 1px;
  width: 100%;
  position: absolute; }
  .schedule .event .name {
    font-weight: bold; }
.schedule .hour-label {
  text-align: right;
  padding: .3rem .5rem 0 0;
  width: 60px; }
.schedule .hour-content {
  flex-grow: 1; }

.report img {
  border: 1px solid #d1d1d1; }
.report .button, .report .menu {
  margin-top: 1.5rem;
  float: left; }

.report-info h3 {
  text-transform: lowercase; }
  .report-info h3::first-letter {
    text-transform: capitalize; }

.header {
  position: fixed;
  box-shadow: 0 0 5px #d1d1d1;
  width: 100%;
  padding: 1rem 0 .5rem;
  background: #FFF;
  z-index: 10;
  top: 0; }
  .header .site-nav, .header h1, .header .control {
    float: left; }
  .header .control {
    padding-bottom: .1rem;
    margin-top: -.15rem; }
  .header .site-nav {
    padding-left: 4vw; }
  .header .button, .header button, .header .document-options {
    float: right; }
  .header h1 {
    font-size: 3em;
    line-height: 1;
    margin: -1px 0;
    padding-left: 1.5rem; }
  .header .logo {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    padding-left: 2.7rem;
    position: relative; }
    .header .logo a {
      text-decoration: none; }
    .header .logo::before {
      content: "A";
      position: absolute;
      left: 3px;
      top: 8px;
      line-height: 30px; }
    .header .logo::after {
      content: "+";
      position: absolute;
      font-weight: bold;
      font-family: Impact, "Trebuchet MS", sans-serif;
      left: 22px;
      top: 8px;
      font-size: 1rem;
      color: #333; }

.site-content, .document-content {
  padding-top: 70px; }

.grade-breakdown {
  width: 340px; }
  .grade-breakdown .grade-breakdown-input-column {
    flex-grow: 1; }
    .grade-breakdown .grade-breakdown-input-column input {
      width: 80px; }
    .grade-breakdown .grade-breakdown-input-column label + input {
      width: auto; }
    .grade-breakdown .grade-breakdown-input-column label {
      z-index: 10; }
  .grade-breakdown td.grade-breakdown-input-column {
    padding-top: .6rem; }
    .grade-breakdown td.grade-breakdown-input-column label {
      padding-top: .5rem; }
  .grade-breakdown .grade-breakdown-label-column {
    width: 120px; }
    .grade-breakdown .grade-breakdown-label-column label {
      color: #666;
      margin: 0;
      padding: 0; }
  .grade-breakdown .grade-breakdown-link-column {
    width: 76px; }
  .grade-breakdown .form-row input[type=submit], .grade-breakdown .form-row button {
    margin-top: .5rem; }

.attendance {
  width: 340px; }
  .attendance .attending-column {
    width: 110px; }
  .attendance td.attending-column {
    padding-top: .6rem; }
  .attendance input[type=text] {
    width: 40px; }
  .attendance .form-row input[type=submit], .attendance .form-row .button {
    margin-top: 0; }
  .attendance .circle-icon {
    margin-top: -10px;
    margin-right: .5rem; }

.calendar-nav .dropdown-wrapper {
  margin-top: 1.2rem; }

.cvc-info {
  width: 400px;
  margin-right: -300px; }
  .cvc-info::before {
    left: 2rem; }
  .cvc-info::after {
    left: 1.5rem; }

.payment-help h3:first-child {
  margin: 3.9rem 0 .5rem; }

#payment .site-nav, #payment .header .button, #login .site-nav, #login .header .button {
  display: none; }

.sign-up {
  float: right;
  margin-top: .8rem; }

.main-section .lead {
  color: #FFF;
  font-size: 1.3rem;
  text-align: center;
  margin: 0 auto;
  max-width: 900px;
  padding-top: 2.5rem; }

.site-content {
  overflow: auto;
  padding-bottom: 3rem; }
  .site-content .video-wrapper {
    width: 100%;
    padding: 2.5rem 0; }
    .site-content .video-wrapper iframe {
      box-shadow: 0 0 5px #111; }

.site-nav {
  font-size: 1.6em;
  font-family: league-gothic, "Trebuchet MS";
  text-transform: uppercase; }
  .site-nav a {
    padding: .3em .2em 0;
    display: inline-block;
    text-decoration: none; }

.site-footer {
  padding: 1em 0;
  clear: both; }
  .site-footer small {
    font-size: 0.9rem; }
  .site-footer .pipe-links {
    float: right;
    margin-top: 2px; }

.social img {
  position: relative;
  top: 7px;
  margin-right: .3rem; }
.social a {
  margin-right: 1rem; }
.social a:last-child img {
  top: 6px; }

.message-bar {
  width: 100%;
  position: fixed;
  top: 70px;
  z-index: 1;
  padding: .25rem 0;
  background-color: #8FAFC6;
  border-top: 1px solid #6B899F;
  border-bottom: 1px solid #6B899F; }
  .message-bar + .site-content {
    padding-top: 6rem; }

.target {
  margin-top: -70px;
  padding-top: 70px; }

.features-section {
  overflow: hidden; }
  .features-section h3 {
    font-size: 2.7rem;
    line-height: 84%;
    text-transform: none; }
  .features-section img {
    position: relative;
    z-index: -1;
    border: 1px solid #d1d1d1;
    border-bottom: 0; }
  .features-section .row {
    overflow: auto;
    padding-bottom: 0;
    box-shadow: 0 -5px 10px -5px #d1d1d1;
    margin-top: -4px;
    z-index: 10;
    padding-top: 1.5rem; }
    .features-section .row .col-6 {
      padding-top: 1.5rem; }
  .features-section .row:nth-child(4n+2) h3 {
    color: #99BD33; }
  .features-section .row:nth-child(4n+3) h3 {
    color: #6B899F; }
  .features-section .row:nth-child(4n+4) h3 {
    color: #F9BD36; }
  .features-section .row:nth-child(4n+5) h3 {
    color: #C6323C; }
  .features-section h2, .features-section .tabs a, .tabs .features-section a, .features-section .report-info h3, .report-info .features-section h3 {
    font-size: 2.7rem;
    text-transform: uppercase;
    text-align: center; }
  .features-section .col-4, .features-section .app-nav:before, .features-section .app-nav:after, .features-section .app-main:before, .features-section .app-main:after, .features-section .header:before, .features-section .header:after, .features-section .container:before, .features-section .container:after {
    padding-bottom: 1.5rem; }

.pricing .col-12 {
  text-align: center; }
.pricing h4 {
  font-size: 2.2rem;
  text-transform: uppercase; }
  .pricing h4 span {
    display: block;
    font-size: 1rem;
    text-transform: none;
    color: #333;
    margin-bottom: -7px;
    font-family: proxima-nova, sans-serif; }
.pricing .price {
  color: #6B899F;
  font-family: league-gothic, "Trebuchet MS";
  font-size: 4rem; }
.pricing .button {
  display: block;
  margin: 0 auto 2.5rem;
  width: 102px; }
.pricing .price-block {
  display: inline-block;
  padding: 1rem 0 1.5rem;
  width: 140px; }

.app-wrapper {
  overflow: hidden; }
  .app-wrapper .fixed-header {
    position: fixed;
    height: 62px;
    top: 8px;
    width: calc(100% - 100px);
    padding: .5rem .75rem .5rem;
    background: #FFF;
    z-index: 20;
    box-shadow: .5rem 5px 5px -5px #d1d1d1; }
  .app-wrapper .full-content {
    overflow: visible; }
  .app-wrapper .secondary-header {
    overflow: auto;
    padding: .75rem 1.5rem .3rem;
    background: #FFF;
    height: 600px; }
  .app-wrapper .fixed-header .primary {
    float: right; }
  .app-wrapper .below-fixed-header {
    margin-top: 68px; }
  .app-wrapper .below-secondary-header {
    margin-top: 145px; }
  .app-wrapper .scroll-columns {
    bottom: 28px;
    left: 100px; }

.app-main {
  width: 100%;
  padding: .5rem 0 0 100px;
  z-index: 10; }

.app-nav {
  box-shadow: inset -15px 0 15px -15px #000;
  width: 100px;
  height: 100%;
  position: fixed;
  z-index: 30;
  padding: calc(.5rem + 8px) .5rem .5rem; }
  .app-nav a {
    color: #FFF;
    text-decoration: none;
    text-align: center;
    display: block;
    font-size: .9em;
    height: 90px;
    padding-top: 70px;
    background-size: 58px;
    background-position: center 14px;
    background-repeat: no-repeat; }
  .app-nav .arrow {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 4px;
    transition: .15s;
    transform: translate(0, 0); }
    .app-nav .arrow.position-1 {
      transform: translate(0, 0); }
    .app-nav .arrow.position-2 {
      transform: translate(0, 90px); }
    .app-nav .arrow.position-3 {
      transform: translate(0, 180px); }
    .app-nav .arrow.position-4 {
      transform: translate(0, 270px); }
    .app-nav .arrow.position-5 {
      transform: translate(0, 360px); }
    .app-nav .arrow.position-6 {
      transform: translate(0, 450px); }
  .app-nav .arrow:before {
    content: "";
    display: block;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #FFF;
    position: relative;
    top: 45px; }
  .app-nav .settings-icon {
    background-image: url(../images/gear.svg); }
  .app-nav .reports-icon {
    background-image: url(../images/reports.svg);
    background-size: 47px;
    background-position: center 17px; }
  .app-nav .planner-icon {
    background-image: url(../images/planner.svg);
    background-size: 42px;
    background-position: center 20px; }
  .app-nav .activities-icon {
    background-image: url(../images/activities.svg);
    background-size: 45px;
    background-position: center 20px; }
  .app-nav .classes-icon {
    background-image: url(../images/classes.svg);
    background-size: 44px;
    background-position: center 20px; }

.app-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  margin-left: 100px;
  background: #FFF;
  padding: .1rem calc(100px + .75rem) 0 .75rem;
  box-shadow: 1rem 5px 5px 5px #d1d1d1;
  z-index: 10; }
  .app-footer .copyright {
    font-size: .9rem;
    margin-top: 2px; }
  .app-footer .pipe-links {
    float: right;
    padding: .1rem 0 .35rem; }

.login-info {
  font-style: normal;
  padding: .8rem 1rem 1.6rem; }
  .login-info ul {
    padding-left: 1rem;
    list-style: none;
    text-indent: -5px; }
    .login-info ul li::before {
      content: "-";
      position: relative;
      left: -5px; }

.document-content {
  height: 100%;
  min-height: 100vh;
  background-attachment: fixed; }

.control {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000; }

.document-options .dropdown-wrapper {
  margin: .8rem 1.5rem; }
.document-options .dropdown-content {
  width: 220px; }
.document-options .transcript-dropdown {
  width: 340px; }
.document-options > .form-row {
  margin-top: .35rem;
  margin-right: 1.5rem; }

.page {
  width: 850px;
  height: 1100px;
  background: #FFF;
  margin: 2rem auto;
  box-shadow: 0 0 5px #d1d1d1;
  padding: 2rem 2rem 5rem;
  font-size: .8rem;
  position: relative; }
  .page .date {
    position: absolute;
    top: 2rem;
    right: 2rem; }
  .page .sq-slot {
    text-align: center;
    font-size: 1.3em;
    padding-top: 10rem; }
  .page table {
    border-bottom: 1px solid #d1d1d1; }
  .page tr {
    border-top: 1px solid #d1d1d1; }
    .page tr td, .page tr th {
      padding: .3rem .4rem; }
  .page address {
    margin-top: -2.25rem;
    text-align: right; }
  .page .compact tr:first-child {
    border-top: 0; }
  .page .compact td, .page .compact th {
    padding: .1rem 0; }
  .page .row {
    margin: 0 -1.5rem;
    padding: .5rem .75rem;
    overflow: auto; }
  .page .section {
    padding-bottom: .5rem; }
  .page .footnote {
    padding-top: .5rem; }

.transcript .section table td:not(:first-child), .transcript .section table th:not(:first-child) {
  text-align: center; }
.transcript .total {
  margin-right: .75rem;
  display: inline-block; }

.signature {
  padding: 1rem 0; }
  .signature .signature-line {
    border-top: 3px solid #666;
    width: 50%;
    margin-top: 5rem; }

.document-row {
  padding: .6rem 0 1rem;
  border-bottom: 1px solid #d1d1d1;
  position: relative;
  overflow: auto;
  min-height: 65px; }
  .document-row p, .document-row span {
    float: left; }
  .document-row p {
    clear: both;
    padding: .4rem 0 0;
    max-width: 70%; }
  .document-row .grade-mark {
    border-top: 3px solid #666;
    width: 68px;
    font-size: .8em;
    color: #666;
    position: absolute;
    bottom: .1rem;
    right: 0; }

h3 + .document-row {
  padding-top: .1rem;
  min-height: 0; }

.totals > * {
  padding-right: .75rem; }

.grade-report header {
  margin-bottom: 3rem; }
.grade-report .grading-table tr:nth-child(2) {
  display: none; }
.grade-report .grading-table td:last-child, .grade-report .grading-table th:last-child {
  display: none; }
.grade-report .grade-report-table td:first-child {
  width: 70%; }
.grade-report .grade-report-table td:nth-child(2) {
  width: 25%; }

.document-information {
  text-align: center; }
  .document-information td {
    text-align: center; }

.class-descriptions .document-information {
  margin: 1rem 0; }

@media print {
  .header {
    display: none; }
  .page {
    margin: 0;
    height: auto;
    width: 100%;
    box-shadow: none;
    page-break-inside: avoid;
    page-break-after: always; }
  .document-content {
    padding-top: 0;
    height: auto;
    background: #FFF; } }

