.metadata {
	h1 {
		float: left;
	}
	
	.dropdown-wrapper {
		float: right;
	}
	
	p {
		padding: 0 0 1rem;
	}
}

.stats-list {
	clear: both;
	list-style: none;
	padding: 1rem 0;
}

.footnote {
	clear: both;
	display: block;
	color: $text-grey;
}