.sq-pagination {
	margin: 1rem 0;
	padding: 0;
	overflow: auto;
	list-style-type: none;
	
	a {
		display: block;
		float: left;
		border: 1px solid #D1D1D1;
		border-right: 0;
		text-decoration: none;
		min-width: 30px;
		text-align: center;
		font-size: .9em;
		padding: .4em;
	}
	
	li:last-child a {
		border-right: 1px solid #D1D1D1;
	}
	
	.number.is-active a {
		background: #E9EAE9;
	}
	
	.prev, .next, .first, .last {
		&.is-active a {
			color: #D1D1D1;
		}
	}
}