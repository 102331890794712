.document-content {
	@extend %below-header;
	@extend %chalkboard;
	
	height: 100%;
	min-height: 100vh;
	background-attachment: fixed;
}

.control {
	@include accelerated;
}

.document-options {
	.dropdown-wrapper {
		margin: .8rem 1.5rem;
	}
	
	.dropdown-content {
		width: 220px;
	}
	
	.transcript-dropdown {
		width: 340px;
	}
	
	> .form-row {
		margin-top: .35rem;
		margin-right: 1.5rem;
	}
}

.page {
	width: 850px;
	height: 1100px;
	background: #FFF;
	margin: 2rem auto;
	box-shadow: 0 0 5px $border-grey;
	padding: 2rem 2rem 5rem;
	font-size: .8rem;
	position: relative;
	
	.date {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}
	
	.sq-slot {
		text-align: center;
		font-size: 1.3em;
		padding-top: 10rem;
	}
	
	table {
		border-bottom: 1px solid $border-grey;
	}
	
	tr {
		border-top: 1px solid $border-grey;
		
		td, th {
			padding: .3rem .4rem;
		}
	}
	
	address {
		margin-top: -2.25rem;
		text-align: right;
	}
	
	.compact {
		tr:first-child {
			border-top: 0;
		}
		
		td, th {
			padding: .1rem 0;
		}
	}
	
	.row {
		margin: 0 -1.5rem;
		padding: .5rem .75rem;
		overflow: auto;
	}
	
	.section {
		padding-bottom: .5rem;
	}
	
	.footnote {
		padding-top: .5rem;
	}
}

.transcript {
	.section table {
		td:not(:first-child), th:not(:first-child) {
			text-align: center;
		}
	}
	
	.total {
		margin-right: .75rem;
		display: inline-block;
	}
}

.signature {
	padding: 1rem 0;
	
	.signature-line {
		border-top: 3px solid $text-grey;
		width: 50%;
		margin-top: 5rem;
	}
}

.document-row {
	padding: .6rem 0 1rem;
	border-bottom: 1px solid $border-grey;
	position: relative;
	overflow: auto;
	min-height: 65px;
	
	p, span {
		float: left;
	}
	
	p {
		clear: both;
		padding: .4rem 0 0;
		max-width: 70%;
	}
	
	.grade-mark {
		border-top: 3px solid $text-grey;
		width: 68px;
		font-size: .8em;
		color: $text-grey;
		position: absolute;
		bottom: .1rem;
		right: 0;
	}
}

h3 + .document-row {
	padding-top: .1rem;
	min-height: 0;
}

.totals > * {
	padding-right: .75rem;
}

.grade-report {
	header {
		margin-bottom: 3rem;
	}
	
	.grading-table tr:nth-child(2) {
		display: none;
	}
	
	.grading-table td:last-child, .grading-table th:last-child {
		display: none;
	}
	
	.grade-report-table {
		td:first-child {
			width: 70%;
		}
		
		td:nth-child(2) {
			width: 25%;
		}
	}
}

.document-information {
	text-align: center;
	
	td {
		text-align: center;
	}
}

.class-descriptions .document-information {
	margin: 1rem 0;
}

@media print {
	.header {
		display: none;
	}
	
	.page {
		margin: 0;
		height: auto;
		width: 100%;
		box-shadow: none;
		page-break-inside: avoid;
		page-break-after: always;
	}
	
	.document-content {
		padding-top: 0;
		height: auto;
		background: #FFF;
	}
}