.color-picker {
	margin: 0 -.2rem 0;
	overflow: hidden;

	.color {
		height: 2.5em;
		width: calc(16.66% - .4rem);
		margin: 0 .2rem 0;
		float: left;
		cursor: pointer;
	
		&.active {
			background-image: url(../images/checkmark.png);
			background-size: 15px;
			background-position: center;
			background-repeat: no-repeat;
			border-width: 5px;
		}
	}
	
	.label {
		margin-left: .2rem;
	}
}

/* Yellow */
.color-0 {
	background-color: #FDDB82;
	border: 1px solid #FABE1E;
}

/* Red */
.color-1 {
	background-color: #E38489;
	border: 1px solid #C83038;
}

/* Green */
.color-2 {
	background-color: #C6DF7A;
	border: 1px solid #98BF21;
}

/* Orange */
.color-3 {
	background-color: #FFB273;
	border: 1px solid #FF7400;
}

/* Purple */
.color-4 {
	background-color: #D89CC9;
	border: 1px solid #B05799;
}

/* Blue */
.color-5 {
	background-color: #8FAFC6;
	border: 1px solid #6B899F;
}

/* Only for dots */
.color-6 {border: 1px solid #00DDDA} /* Cyan */
.color-7 {border: 1px solid #FC00CD} /* Pink */
.color-8 {border: 1px solid #007B2B} /* Dark Green */