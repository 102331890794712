
// Top Stripe Bar
.app-nav, .app-main, .header, .container {
	&:before, &:after {
		@extend .col-4;
		
		@include accelerated;
		
		content: "";
		position: fixed;
		top: 0;
		left: 0;
		padding: 0;
		height: 8px;
	}
}

.app-nav, .container {	
	&:before {
		left: 0;
		background: $green;
	}
	
	&:after {
		left: 25%;
		width: 25%;
		background: $blue;
	}
}

.app-main, .header {	
	&:before {
		left: 50%;
		background: $yellow;
		z-index: 15;
	}
	
	&:after {
		left: 75%;
		background: $red;
		z-index: 20;
	}
}