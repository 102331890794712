input, textarea, select {
	font-family: $font-proxima;
}

.form-row {
	clear: both;
}

input[type=text], input[type=password], input[type=date], input[type=email], select, textarea, .field {
	height: 35px;
	font-size: 1rem;
	border: 1px solid darken($light-grey, 10%);
	width: 100%;
	padding-left: .5rem;
	
	&.inline {
		padding-left: 6rem;
		margin-top: 1.5rem;
		
		td & {
			margin: 0;
		}
	}
	
	.first &.inline {
		margin-top: .5rem;
	}
	
	&:focus {
		outline: none;
		border: 1px solid $blue;
	}
	
	&.error {
		border: 1px solid $red;
	}
	
	&.valid {
		border: 1px solid $green;
	}
	
	&:disabled {
		opacity: .8;
	}
}

td select {
	height: 25px;
	background: transparent;
	width: 120px;
	margin-top: -3px;
}

textarea {
	height: 150px;
	padding: .45rem;
	resize: vertical;
}

input[type=radio], input[type=checkbox] {
	float: left;
	cursor: pointer;
	margin: 1rem 0 .4rem;
}

input[type=text], input[type=password], input[type=date], input[type=email], textarea, .field {
	border-radius: .4rem;
	box-shadow: inset 0 0 4px #999;
	-webkit-appearance: none;
	
	&:focus {
		box-shadow: inset 0 0 4px $blue;
	}
	
	&.error {
		box-shadow: inset 0 0 4px $red;
	}
	
	&.valid {
		box-shadow: inset 0 0 4px $green;
	}
}

label, .label {
	padding: .5rem 0 .1rem;
	display: block;
	cursor: pointer;
	color: $text-grey;
	
	&.checkbox {
		float: left;
		padding-left: .25em;
		margin-top: .4em;
		position: relative;
		top: -6px;
	}
	
	&.inline {
		position: absolute;
		padding: 2rem .5em;
		z-index: 3;
		
		td & {
			padding-top: .8rem;
		}
	}
	
	&.error {
		color: $red;
		padding-top: .2rem;
	}
	
	.first &.inline {
		padding-top: 1rem;
	}
}

.after-input {
	display: inline-block;
	vertical-align: top;
	margin-top: 7px;
	
	a {
		display: block;
		padding-left: .5rem;
	}
}

.reveal-input, .clear-input {
	position: absolute;
	right: .75rem;
	top: 2.3rem;
}

.reveal-input {
	opacity: 0;
	transition: opacity .2s;
	
	&.is-active {
		opacity: 1;
	}
}

input[type=checkbox] {
	width: auto;
	margin-top: 12px;
	display: inline;
}

.form-row {
	position: relative;
	
	&.auto-width {
		input, select {
			width: auto;
		}
	}
	
	.button, button, input[type=submit] {
		margin-top: 1.5rem;
		
		&.secondary {
			float: right;
		}
	}
}

.form-inline {
	float: left;
	padding-right: .75rem;
}

.grade-box {
	width: 200px;
	text-transform: uppercase;
}

input[type=file] {
	padding-bottom: .95rem;
}

.sq-input-image-current {
	width: 64px;
	height: 64px;
	overflow: hidden;
	border-radius: 32px;
	float: left;
	margin-right: .5rem;
	
	span {
		height: 64px;
		width: 64px;
		background-size: cover;
		background-position: center;
		display: block;
	}
	
	img {
		display: none;
	}
}

.terms-checkbox {
	border-top: 1px solid $border-grey;
	border-bottom: 1px solid $border-grey;
	overflow: auto;
	margin-top: 1rem;
	
	input[type=checkbox] {
		position: absolute;
		left: 0;
		top: 1px;
	}
	
	label {
		padding-left: 1.2rem;
	}
	
	.error {
		padding-bottom: .5rem;
	}
}


////////////////////////////////////////////////////////////////////////////////
// Grade Method Picker
////////////////////////////////////////////////////////////////////////////////

.grading-method {
	margin: -1.4rem 0 1rem;
	
	label, input {
		display: inline-block;
		padding-top: .75rem;
	}
	
	input {
		margin-right: .7rem;
	}
	
	.grade-icon {
		top: -.3rem;
		margin-right: .5rem;
	}
	
	div {
		width: 140px;
		float: left;
	}
}


////////////////////////////////////////////////////////////////////////////////
// Attendance Method Picker
////////////////////////////////////////////////////////////////////////////////

.attendance-method {
	div {
		width: 140px;
		float: left;
	}
}


////////////////////////////////////////////////////////////////////////////////
// Time Field
////////////////////////////////////////////////////////////////////////////////

.time-picker {
	position: relative;
	overflow: auto;
	clear: both;

	&:before {
		content: ":";
		position: absolute;
		top: 2.2rem;
		left: 1.85rem;
	}
	
	input[type=text] {
		position: absolute;
		top: 1.8rem;
		padding: 0;
		border: 0;
		box-shadow: none;
		width: 1.8em;
		background: none;
		
		&:focus {
			border: 0;
			box-shadow: none;
		}
	}
	
	.minutes {
		left: 2.5rem;
	}
	
	.prefix {
		right: .35rem;
		position: absolute;
		margin: 0;
		top: 2.15rem;
		height: auto;
		width: auto;
	}
}


////////////////////////////////////////////////////////////////////////////////
// Fieldsets
////////////////////////////////////////////////////////////////////////////////

fieldset {
	border: 0;
	margin-bottom: .5rem;
}

legend {
	color: #777;
}

.checkbox-group {
	margin-top: .6rem;
	
	.form-row {
		overflow: auto;
		border-bottom: 1px solid darken($light-grey, 10%);
	
		&:first-of-type {
			border-top: 1px solid darken($light-grey, 10%);
		}
	}
	
	select {
		padding: .2rem .3rem;
		margin-top: .4rem;
		height: auto;
		width: 130px;
	}
	
	.error {
		float: right;
		clear: both;
	}
	
	.grade-level {
		transition: all .2s;
		float: right;
		opacity: 0;
		visibility: hidden;
	}
	
	:checked + .grade-level {
		opacity: 1;
		visibility: visible;
	}
}


////////////////////////////////////////////////////////////////////////////////
// Attendance Form
////////////////////////////////////////////////////////////////////////////////

.attendance-form {
	input[type=text] {
		width: 2em;
	}
}